import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { StatusIndicator } from "../../components/StatusIndicator";

export const displayProductStatus = (status: Product["status"]) => {
  switch (status) {
    case "incomplete":
      return "Data missing";
    case "draft":
      return "Ready for verification";
    case "under-review":
      return "Awaiting verification";
    case "completed":
      return "EPD published & verified";
  }
};

export const ProductStatusIndicator = ({ status }: { status: Product["status"] }) => {
  const { t } = useTranslation();

  return (
    <StatusIndicator
      text={t(displayProductStatus(status))}
      missing={status === "incomplete"}
      waiting={status === "under-review"}
      ready={status === "draft"}
      done={status === "completed"}
    />
  );
};
