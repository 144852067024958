import { useTranslation } from "react-i18next";
import { ImpactSummary } from "../../api/types";
import { Label, Label12 } from "../../components/Typography";
import { LifeCycleStagesChart } from "./LifeCycleStagesChart";

import colors from "tailwindcss/colors";
import { InfoTooltip } from "../../components/InfoTooltip";
import { coreIndicators, filterImpact } from "../../lca/impact";
import { useImpactColors } from "./useImpactColors";
export const yellow300 = colors.yellow[300];
export const yellow200 = colors.yellow[200];
export const blue300 = colors.blue[300];
export const blue200 = colors.blue[200];
export const neutral100 = colors.neutral[100];
export const neutral200 = colors.neutral[200];
export const neutral300 = colors.neutral[300];

export const BreakdownPerStage = ({ impactSummary }: { impactSummary: ImpactSummary }) => {
  const { t } = useTranslation();
  const stages = Object.values(impactSummary).filter((x) => x !== null);
  if (stages.length < 2) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Label className="flex items-center gap-2">
        {t("Breakdown: Lifecycle Stages")}
        <InfoTooltip>
          <p>{t("The life cycle of a construction product encompasses the following stages:")}</p>
          <ol className="list-decimal pl-8">
            <li>
              <strong>{t("Production")}:</strong>{" "}
              {t(
                "this stage includes all processes required to transform raw materials into a finished construction product.",
              )}
            </li>
            <li>
              <strong>{t("Installation")}:</strong>{" "}
              {t(
                "this stage includes all activities related to the installation of the product at the construction site.",
              )}
            </li>
            <li>
              <strong>{t("Use")}:</strong>{" "}
              {t(
                "this stage covers the operational life of the construction product, during which it performs its intended function.",
              )}
            </li>
            <li>
              <strong>{t("End-of-life")}:</strong>{" "}
              {t(
                "this stage includes all activities related to the deconstruction, disposal, recycling, or repurposing of the construction product after its useful life.",
              )}
            </li>
            <li>
              <strong>{t("Benefits and loads beyond service life")}:</strong>{" "}
              {t(
                "this stage considers the benefits and impacts of recycling, reusing, or recovering materials after the product's life cycle has ended.",
              )}
            </li>
          </ol>
        </InfoTooltip>
      </Label>
      <div className="w-full h-[200px]">
        <LifeCycleStagesChart impactSummary={impactSummary} />
      </div>
      <CustomLegend impactSummary={impactSummary} />
    </div>
  );
};

const CustomLegend = ({ impactSummary }: { impactSummary: ImpactSummary }) => {
  const { t } = useTranslation();
  const colors = useImpactColors();

  return (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center rounded-lg">
        {filterImpact(impactSummary.a1_a3, coreIndicators).map((impact) => (
          <div key={impact.indicator_name} className="flex items-center gap-1 px-3 py-2">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: colors[impact.indicator_name] }}
            />
            <Label12 className="text-neutral-500">{t(impact.indicator_name)}</Label12>
          </div>
        ))}
      </div>
    </div>
  );
};
