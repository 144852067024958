import { useTranslation } from "react-i18next";
import { LciaResults, SourceImpacts } from "../../api/types";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Label12 } from "../../components/Typography";
import { coreIndicators, filterImpact } from "../../lca/impact";
import { ImpactsByElementaryTypeChart } from "./ImpactsByElementaryTypeChart";
import { useImpactColors } from "./useImpactColors";

export const SourcesOfEnvironmentalImpact = ({ lcia }: { lcia: LciaResults }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <Label12 className="flex items-center gap-2">
        {t("Overview: Sources of environmental impact")}
        <InfoTooltip>
          {t(
            "This is this product's environmental impact broken down into their sources - to help you get a first overview of your reduction leverages.",
          )}
        </InfoTooltip>
      </Label12>
      <div className="w-full h-[200px]">
        <ImpactsByElementaryTypeChart lcia={lcia} />
      </div>
      {lcia.impacts_per_source.length > 0 && (
        <CustomLegend impactsPerSource={lcia.impacts_per_source} />
      )}
    </div>
  );
};

const CustomLegend = ({ impactsPerSource }: { impactsPerSource: SourceImpacts[] }) => {
  const { t } = useTranslation();
  const colors = useImpactColors();

  return (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center rounded-lg">
        {filterImpact(impactsPerSource[0].impacts_total, coreIndicators).map((impact) => (
          <div key={impact.indicator_name} className="flex items-center gap-1 px-3 py-2">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: colors[impact.indicator_name] }}
            />
            <Label12 className="text-neutral-500">{t(impact.indicator_name)}</Label12>
          </div>
        ))}
      </div>
    </div>
  );
};
