import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Heading3 } from "./Typography";

export const ConfirmationModal = ({
  title,
  content,
  onConfirm,
  onCancel,
  danger,
}: {
  title: string;
  content: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  danger?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={() => onCancel()}>
      <div className="px-6 py-4">
        <Heading3>{title}</Heading3>
      </div>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button intent="secondary" onPress={onCancel}>
          {t("Cancel")}
        </Button>
        <Button onPress={onConfirm} intent={danger ? "danger" : "primary"}>
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
