import { useCallback } from "react";
import { PlantInput } from "../types";
import { useApi } from "../useApi";

export const useGetPlantInputs = () => {
  const api = useApi<PlantInput[]>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      type,
    }: {
      manufacturerId: string;
      plantId: string;
      type?: "raw_materials" | "packaging";
    }) => {
      const searchParams = new URLSearchParams();

      if (type) {
        searchParams.set("type", type);
      }

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/inputs?${searchParams.toString()}`,
      });
    },
    [api],
  );
};
