import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

const initialState = {
  splash: { message: "" },
  setSplash: () => {},
};

export interface Splash {
  message: string;
}

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [splash, setSplash] = useState<Splash | undefined>(undefined);

  return { splash, setSplash };
};

export const SplashProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useSplash = () => {
  return useContext(Context);
};
