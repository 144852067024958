import { Add, ArrowRightAltOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import { Label, Text16 } from "../../components/Typography";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useInputs, useInputsLoader } from "../../state/inputs";
import { useActivePlant } from "../../state/plants";

export const EmptyProductList = () => {
  useInputsLoader();

  const { t } = useTranslation();
  const { activePlant } = useActivePlant();
  const { rawMaterials } = useInputs();

  const plantProducesCategories = useMemo(() => {
    return activePlant?.production_processes.map((pp) => pp.category_id);
  }, [activePlant]);

  const hasNoProcesses = (activePlant?.production_processes.length || 0) === 0;
  const hasNoIOFilled = activePlant?.production_processes.every(
    (pp) => (pp.input_output_per_year?.length || 0) === 0,
  );
  const hasNoMaterials = rawMaterials.length === 0;
  const needsMaterials = !plantProducesCategories?.some((categoryId) =>
    CATEGORIES_WITHOUT_RECIPE.includes(categoryId),
  );

  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
      <div className="flex items-center gap-3">
        <Label>{t("No products added yet")}</Label>
      </div>
      {hasNoProcesses ? (
        <>
          <Text16>{t("You'll have to specify your production details first")}</Text16>
          <Link intent="secondary" href="/production">
            <ArrowRightAltOutlined />
            {t("Add a production processes")}
          </Link>
        </>
      ) : hasNoMaterials && needsMaterials ? (
        <>
          <Text16>{t("You'll have add supplier materials first")}</Text16>
          <Link intent="secondary" href="/suppliers-and-materials">
            <ArrowRightAltOutlined />
            {t("Set up supplier materials")}
          </Link>
        </>
      ) : hasNoIOFilled ? (
        <>
          <Text16>{t("You'll have to fill out the plant's production output first")}</Text16>
          <Link intent="secondary" href="/production">
            <ArrowRightAltOutlined />
            {t("Add plant production output")}
          </Link>
        </>
      ) : (
        <Link href="/edit/product-details">
          <Add />
          {t("Add new product")}
        </Link>
      )}
    </div>
  );
};
