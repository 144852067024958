import { useTranslation } from "react-i18next";
import { EndOfScale, ImpactSummary } from "../../api/types";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Label } from "../../components/Typography";
import { coreIndicators, filterImpact } from "../../lca/impact";
import { ImpactFactors } from "./ImpactFactors";
import { RadarChart } from "./RadarChart";

export const EnvironmentalPerformanceIndicators = ({
  impactSummary,
  endsOfScale,
}: {
  impactSummary: ImpactSummary;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();

  const filteredImpacts = filterImpact(impactSummary.a1_a3, coreIndicators);

  return (
    <div className="flex flex-col gap-3">
      <Label className="flex items-center gap-2">
        {t("Environmental Performance Indicators")}
        <InfoTooltip>
          {t(
            "Overview of the environmental performance indicators for the product, based on the A1-A3 stages of the life cycle.",
          )}
        </InfoTooltip>
      </Label>
      <div className="grid grid-cols-[1fr_1.681fr] gap-8">
        <div className="h-[250px]">
          <RadarChart impacts={filteredImpacts} endsOfScale={endsOfScale} />
        </div>
        <div className="flex items-center">
          <ImpactFactors impacts={filteredImpacts} />
        </div>
      </div>
    </div>
  );
};
