import { useCallback } from "react";
import { Manufacturer } from "../types";
import { apiLink, useApi } from "../useApi";

export const useGetMe = () => {
  return useApi<Manufacturer>({ url: `/v1/me/manufacturers` });
};

export const usePostManufacturer = () => {
  const api = useApi<Manufacturer>({
    url: `/v1/manufacturers`,
    method: "POST",
  });

  return useCallback(
    (data: Omit<Manufacturer, "id" | "owner" | "input_output_schema" | "image_url">) =>
      api({ data }),
    [api],
  );
};

export const usePatchManufacturer = () => {
  const api = useApi<Manufacturer>({
    method: "PUT",
  });

  return useCallback(
    ({
      manufacturer,
      manufacturerId,
    }: {
      manufacturerId: string;
      manufacturer: Omit<Manufacturer, "owner">;
    }) => api({ url: `/v1/manufacturers/${manufacturerId}`, data: manufacturer }),
    [api],
  );
};

export const useUploadImage = () => {
  const api = useApi<string>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ manufacturerId, file }: { manufacturerId: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({ url: `/v1/manufacturers/${manufacturerId}/image`, data: formData });
    },
    [api],
  );
};

const useImageToken = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    async ({ manufacturerId, path }: { manufacturerId: string; path: string }) =>
      await api({
        url: `/v1/manufacturers/${manufacturerId}/image/access-token?path=${encodeURIComponent(path)}`,
      }),
    [api],
  );
};

export const useImage = () => {
  const tokenResolver = useImageToken();

  return useCallback(
    async ({ manufacturerId, path }: { manufacturerId: string; path: string }) => {
      const token = await tokenResolver({ manufacturerId, path });

      return apiLink(
        `/v1/manufacturers/${manufacturerId}/image?path=${encodeURIComponent(path)}&token=${token}`,
      );
    },
    [tokenResolver],
  );
};
