import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { RouterProvider as ReactAriaRouterProvider } from "react-aria-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Auth0ProviderWithNavigate } from "./auth/Auth0ProviderWithNavigate";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { Loading } from "./components/Loading";
import { useLanguageSwitch } from "./localization/useLanguageSwitch";
import { ManageSuppliersAndMaterials } from "./page-components/company-settings/ManageSuppliersAndMaterials";
import { NewProductionProcess } from "./page-components/plant-settings/NewProductionProcess";
import { APIAccess } from "./pages/APIAccess";
import { CompanySettings } from "./pages/CompanySettings";
import { EPDTool } from "./pages/EPDTool";
import { AddPlant, EditPlant } from "./pages/EditOrAddPlant";
import { Index } from "./pages/Index";
import { ProductDetailPage } from "./pages/ProductDetailPage";
import { Production } from "./pages/Production";
import { Products } from "./pages/Products";
import { Search } from "./pages/Search";
import { UserProfile } from "./pages/UserProfile";
import { Edit } from "./pages/edit/Edit";
import { ProductDetails } from "./pages/edit/ProductDetails";
import { ProductProductionProcess } from "./pages/edit/ProductProductionProcess";
import { ProductRecipe } from "./pages/edit/ProductRecipe";
import { ProductSpecs } from "./pages/edit/ProductSpecs";
import { Publication } from "./pages/edit/Publication";
import { Results } from "./pages/edit/Results";
import { Verification } from "./pages/edit/Verification";
import { ErrorPage } from "./pages/special/ErrorPage";
import { NotFoundPage } from "./pages/special/NotFoundPage";
import { AdminModeProvider } from "./state/admin";
import { BannerProvider } from "./state/banner";
import { ElementariesProvider } from "./state/elementaries";
import { InputsProvider } from "./state/inputs";
import { LCIAResultsProvider } from "./state/lciaResults";
import { ManufacturersProvider } from "./state/manufacturers";
import { NavigationProvider } from "./state/navigation";
import { PlantsProvider } from "./state/plants";
import { ProcessFlowsProvider } from "./state/processFlows";
import { ProductsProvider } from "./state/products";
import { ProfilesProvider } from "./state/profiles";
import { SplashProvider } from "./state/splash";
import { SuppliersProvider } from "./state/suppliers";

const queryClient = new QueryClient();

const AppStateProviders = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  useLanguageSwitch();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactAriaRouterProvider navigate={navigate}>
        <BannerProvider>
          <SplashProvider>
            <NavigationProvider>
              <AdminModeProvider>
                <ProfilesProvider>
                  <ElementariesProvider>
                    <ManufacturersProvider>
                      <PlantsProvider>
                        <SuppliersProvider>
                          <InputsProvider>
                            <ProductsProvider>
                              <ProcessFlowsProvider>
                                <LCIAResultsProvider>
                                  <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                                </LCIAResultsProvider>
                              </ProcessFlowsProvider>
                            </ProductsProvider>
                          </InputsProvider>
                        </SuppliersProvider>
                      </PlantsProvider>
                    </ManufacturersProvider>
                  </ElementariesProvider>
                </ProfilesProvider>
              </AdminModeProvider>
            </NavigationProvider>
          </SplashProvider>
        </BannerProvider>
      </ReactAriaRouterProvider>
    </QueryClientProvider>
  );
};

const Layout = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({});
    return;
  }

  return (
    <AppStateProviders>
      <div className="flex flex-col items-start w-full h-screen">
        <Outlet />
      </div>
    </AppStateProviders>
  );
};

const AuthLayout = () => (
  <Auth0ProviderWithNavigate>
    <Layout />
  </Auth0ProviderWithNavigate>
);

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <Index />,
            path: "/",
            errorElement: <ErrorPage />,
            children: [
              {
                path: "/",
                element: <Products />,
              },
              {
                path: "/products",
                element: <Products />,
              },
              {
                path: "/edit",
                element: <Edit />,
                children: [
                  {
                    path: "/edit/product-details",
                    element: <ProductDetails />,
                  },
                  {
                    path: "/edit/product-specs",
                    element: <ProductSpecs />,
                  },
                  {
                    path: "/edit/product-production-process",
                    element: <ProductProductionProcess />,
                  },
                  {
                    path: "/edit/product-recipe",
                    element: <ProductRecipe />,
                  },
                  // TODO: We might bring back the process editor in the future
                  // {
                  //   path: "/edit/process-flow",
                  //   element: <Process mode="flow" />,
                  // },
                  // {
                  //   path: "/edit/process-materials",
                  //   element: <Process mode="materials" />,
                  // },
                  // TODO: EVALUATE THE FOLLOWING PAGES – DO WE NEED THEM?
                  {
                    path: "/edit/verification",
                    element: <Verification />,
                  },
                  {
                    path: "/edit/publication",
                    element: <Publication />,
                  },
                  {
                    path: "/edit/results",
                    element: <Results />,
                  },
                ],
              },
              {
                path: "/products/:id",
                element: <ProductDetailPage />,
              },
              {
                path: "/company-settings",
                element: <CompanySettings />,
              },
              {
                path: "/production",
                element: <Production />,
              },
              {
                path: "/production/new-production-process",
                element: <NewProductionProcess />,
              },
              {
                path: "/manage-plants/edit",
                element: <EditPlant />,
              },
              {
                path: "/manage-plants/new",
                element: <AddPlant />,
              },
              {
                path: "/suppliers-and-materials/*",
                element: <ManageSuppliersAndMaterials />,
              },
              {
                path: "/search",
                element: <Search />,
              },
              {
                path: "/epd-tool",
                element: <EPDTool />,
              },
              {
                path: "/api-access",
                element: <APIAccess />,
              },
              {
                path: "/profile",
                element: <UserProfile />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={router} />;
};
