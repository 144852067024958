import { useMemo } from "react";
import { DynamicFormFieldConfig, DynamicFormJSONSchema } from "../api/types";
import { ComboBoxFieldConnected } from "../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../form-components/NumberFieldConnected";
import { TextFieldConnected } from "../form-components/TextFieldConnected";

// Well, I wish it wasn't this complex.
// Let's please try and get subfields removed from the app again soon.
// eslint-disable-next-line complexity
export const JSONSchemaFieldConnected = ({
  fieldName,
  fieldConfig,
  schema,
  fieldSchema,
  isRequired: _isRequired,
  isDisabled: _isDisabled,
  subfieldsSchema,
}: {
  fieldName: string;
  fieldConfig: DynamicFormFieldConfig;
  schema: DynamicFormJSONSchema;
  fieldSchema?: DynamicFormJSONSchema;
  isRequired?: boolean;
  isDisabled?: boolean;
  subfieldsSchema?: DynamicFormJSONSchema;
}) => {
  const isDisabled = _isDisabled || fieldConfig.readOnly;
  const isRequired =
    _isRequired ||
    schema.required?.includes(fieldName) ||
    fieldSchema?.required?.includes(fieldName);

  const subfields = useMemo(() => {
    if (!subfieldsSchema) return undefined;

    return (
      <div className="flex flex-col gap-3">
        {Object.entries(subfieldsSchema.properties).map(([subFieldName, subFieldConfig]) => {
          const actualSubfieldName = `${fieldName.replace(".value", "")}.attributes.${subFieldName}`;
          return (
            <JSONSchemaFieldConnected
              key={actualSubfieldName}
              fieldName={actualSubfieldName}
              fieldConfig={subFieldConfig}
              schema={schema}
              fieldSchema={fieldSchema}
              isRequired={isRequired}
              isDisabled={isDisabled}
            />
          );
        })}
      </div>
    );
  }, [fieldName, fieldSchema, isDisabled, isRequired, schema, subfieldsSchema]);

  let { type } = fieldConfig;
  const { anyOf, oneOf, allOf } = fieldConfig;

  if (allOf) {
    // Now we need to find the actual field config
    // within the global defs
    const firstAllOfRef = allOf[0].$ref;
    const actualFieldConfig = schema?.$defs[firstAllOfRef.replace("#/$defs/", "")];

    const valueFieldConfig = actualFieldConfig?.properties?.value;

    const attributesFieldConfig = actualFieldConfig?.properties?.attributes;
    let attributesSchema;
    if (attributesFieldConfig?.$ref) {
      attributesSchema = schema?.$defs[attributesFieldConfig.$ref.replace("#/$defs/", "")];
    }

    // Now we assume that we've got this highly custom field config
    // with a "value", plus sub-fields contained in "attributes"
    return (
      <>
        <JSONSchemaFieldConnected
          key={`${fieldName}.value`}
          fieldName={`${fieldName}.value`}
          fieldConfig={valueFieldConfig}
          schema={schema}
          fieldSchema={fieldSchema}
          isRequired={_isRequired}
          isDisabled={_isDisabled}
          subfieldsSchema={attributesSchema}
        />
      </>
    );
  }

  if (!type) {
    if (!anyOf) {
      console.error("Unsupported field", type, fieldConfig);
      return null;
    }

    type = anyOf.find((x) => x.type !== "null")?.type;
  }

  switch (type) {
    case "number":
    case "integer":
      return (
        <NumberFieldConnected<Record<string, unknown>>
          name={fieldName}
          defaultValue={fieldConfig.default !== undefined ? Number(fieldConfig.default) : undefined}
          label={fieldConfig.title}
          isRequired={isRequired}
          isDisabled={isDisabled}
          inputProps={{
            addonRight: fieldConfig.unit,
            ...(fieldConfig.type === "integer" ? { min: 0 } : {}),
          }}
          subfields={subfields}
        />
      );
    case "string":
      if (oneOf) {
        const options = oneOf.map((option) => ({ label: option.title, id: option.const }));
        return (
          <ComboBoxFieldConnected<Record<string, unknown>>
            name={fieldName}
            label={fieldConfig.title}
            isDisabled={isDisabled}
            isRequired={isRequired}
            options={options}
          />
        );
      }

      return (
        <TextFieldConnected<Record<string, unknown>>
          name={fieldName}
          label={fieldConfig.title}
          isDisabled={isDisabled}
          isRequired={isRequired}
          subfields={subfields}
        />
      );
    default:
      console.error("Unsupported field type", fieldConfig.type, fieldConfig);
      return null;
  }
};
