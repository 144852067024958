import { useCallback } from "react";
import { EPDSearchResult } from "../types";
import { useApi } from "../useApi";

export const usePostSearchResults = () => {
  const api = useApi<EPDSearchResult[]>();

  return useCallback(
    (params: { searchTerm: string; language?: string }) => {
      const searchParams = new URLSearchParams();

      searchParams.set("query", params.searchTerm);
      if (params.language) {
        searchParams.set("language", params.language);
      }

      return api({ url: `/v1/epds/advanced_search?${searchParams.toString()}` });
    },
    [api],
  );
};
