import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteProduct } from "../../api/endpoints/products";
import { Product } from "../../api/types";
import { showSuccessToast } from "../../util/toasts";

export const useProductDeletion = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const deleteProduct = useDeleteProduct();

  const removeProduct = async () => {
    try {
      await deleteProduct({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      });

      setDeletionStaged(false);
      showSuccessToast(t(`Successfully deleted product '{{ name }}'`, { name: product.name }));
      navigate("/products");
    } catch (e) {
      console.error(e);
    }
  };

  return { deletionStaged, setDeletionStaged, removeProduct };
};
