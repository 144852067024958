import { UploadFileOutlined } from "@mui/icons-material";
import { Button as ButtonPrimitive, FileTrigger } from "react-aria-components";
import { Product } from "../../api/types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { useImage, useUploadImage } from "../../api/endpoints/manufacturers";
import { usePatchProduct } from "../../api/endpoints/products";
import aggregates from "../../assets/product-logos/aggregates.png";
import asphalt from "../../assets/product-logos/asphalt.png";
import cement from "../../assets/product-logos/cement.png";
import clinker from "../../assets/product-logos/clinker.png";
import ggbs from "../../assets/product-logos/ggbs.png";
import insulation from "../../assets/product-logos/glass_wool_insulation.png";
import greenroofsystem from "../../assets/product-logos/green_roof_systems.png";
import precastconcrete from "../../assets/product-logos/precast_concrete.png";
import readymixconcrete from "../../assets/product-logos/ready_mix_concrete.png";
import waterproofingresin from "../../assets/product-logos/waterproofing_resin.png";
import {
  HARD_CODED_AGGREGATES_ID,
  HARD_CODED_ASPHALT_ID,
  HARD_CODED_CEMENT_ID,
  HARD_CODED_CLINKER_ID,
  HARD_CODED_CONCRETE_CATEGORY_ID,
  HARD_CODED_GGBS_ID,
  HARD_CODED_GREEN_ROOF_SYSTEMS_ID,
  HARD_CODED_MINERAL_INSULATING_MATERIALS_ID,
  HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID,
  HARD_CODED_REACTION_RESIN_ID,
} from "../../state/HARD_CODED";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useProductLoader } from "../../state/products";
import { showErrorToast } from "../../util/toasts";

const hardcodedProductImagesByCategory: {
  [key: string]: string;
} = {
  [HARD_CODED_CONCRETE_CATEGORY_ID]: readymixconcrete,
  [HARD_CODED_CEMENT_ID]: cement,
  [HARD_CODED_CLINKER_ID]: clinker,
  [HARD_CODED_GGBS_ID]: ggbs,
  [HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID]: precastconcrete,
  [HARD_CODED_MINERAL_INSULATING_MATERIALS_ID]: insulation,
  [HARD_CODED_AGGREGATES_ID]: aggregates,
  [HARD_CODED_REACTION_RESIN_ID]: waterproofingresin,
  [HARD_CODED_ASPHALT_ID]: asphalt,
  [HARD_CODED_GREEN_ROOF_SYSTEMS_ID]: greenroofsystem,
};

const SxButton = tw(ButtonPrimitive)`
  w-full
  h-full
  z-20
  absolute
  bg-neutral-900
  bg-opacity-40
  top-0
  left-0
  opacity-0
  group-hover:opacity-100
  transition-opacity
  cursor-pointer
  text-white
  flex
  items-center
  justify-center
`;

export const ProductImage = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  const { refetch } = useProductLoader(product.id);

  const uploadImage = useUploadImage();
  const updateProduct = usePatchProduct();

  const getImageAccessLink = useImage();

  const defaultImage = hardcodedProductImagesByCategory[product.category.id];

  const [imageAccessLink, setImageAccessLink] = useState<string | null>(null);

  const handleFileDrop = async (files: FileList | null) => {
    const file = files?.[0];
    if (!file || !activeManufacturer || !activePlant) return;

    try {
      const key = await uploadImage({
        manufacturerId: activeManufacturer.id,
        file,
      });
      await updateProduct({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        productId: product.id,
        product: { ...product, image_url: key },
      });
      refetch();
    } catch (_) {
      showErrorToast(t("Couldn't upload company logo."));
    }
  };

  useEffect(() => {
    const getImageSrc = async () => {
      if (!product?.image_url || !activePlant || !activeManufacturer) return;
      try {
        const img = await getImageAccessLink({
          manufacturerId: activeManufacturer.id,
          path: product.image_url,
        });
        setImageAccessLink(img);
      } catch (error) {
        console.error("Error generating image url:", error);
      }
    };
    getImageSrc();
  }, [activeManufacturer, activePlant, product, getImageAccessLink]);

  return (
    <div className="shrink-0 w-[200px] h-[200px] p-[2px] bg-white relative group">
      <img
        className="w-full h-full object-cover"
        src={imageAccessLink || defaultImage}
        alt="Product"
      />
      <FileTrigger onSelect={handleFileDrop}>
        <SxButton>
          <UploadFileOutlined fontSize="large" />
        </SxButton>
      </FileTrigger>
    </div>
  );
};
