import {
  ArrowBack,
  Circle,
  DeleteOutlined,
  DescriptionOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { TabSwitch } from "../../components/TabSwitch";
import { TopBar } from "../../components/TopBar";
import { displayProductStatus } from "../products/ProductStatusIndicator";

export const ProductDetailTopBar = ({
  hasDocuments,
  hasPublished,
  status,
  activeTab,
  setActiveTab,
  onEdit,
  onDelete,
  onOpenSidePanel,
}: {
  hasDocuments?: boolean;
  hasPublished?: boolean;
  status: Product["status"];
  activeTab: "passport" | "analyse";
  setActiveTab: Dispatch<SetStateAction<"passport" | "analyse">>;
  onEdit: () => void;
  onDelete: () => void;
  onOpenSidePanel: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TopBar
      variant="concrete"
      icon={
        <Button intent="secondary" square onPress={() => navigate("/")}>
          <ArrowBack />
        </Button>
      }
      title={t("Product Details")}
      subtitle={
        <span className="flex items-center gap-2">
          <span>{t("Status")}:</span>
          <span>{t(displayProductStatus(status))}</span>
        </span>
      }
      extra={
        <TabSwitch
          options={[
            { label: "Passport", value: "passport" as const },
            { label: "Analyse", value: "analyse" as const },
          ]}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab as "passport" | "analyse")}
        />
      }
      input={
        <>
          {(status === "draft" || status === "incomplete") && (
            <>
              <Button intent="transparent" onPress={onDelete}>
                {t("Delete product")}
                <DeleteOutlined />
              </Button>
              <Button intent="secondary" onPress={onEdit}>
                {t("Edit product")}
                <EditOutlined />
              </Button>
            </>
          )}
          <Button onPress={onOpenSidePanel}>
            {hasPublished
              ? t("EPD verified & published")
              : hasDocuments
                ? t("Documents")
                : t("Generate EPD")}
            {hasPublished ? <Circle /> : <DescriptionOutlined />}
          </Button>
        </>
      }
    />
  );
};
