import { AddOutlined, Commit, TokenOutlined, UploadOutlined } from "@mui/icons-material";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { DialogTrigger, FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBulkUploadMaterials } from "../../../api/endpoints/materials";
import { PlantInput } from "../../../api/types";
import { Button } from "../../../components/Button";
import { DataGrid } from "../../../components/DataGrid";
import { Link } from "../../../components/Link";
import { PageContainer } from "../../../components/PageContainer";
import { Popover } from "../../../components/Popover";
import { StatusIndicator } from "../../../components/StatusIndicator";
import { TopBar } from "../../../components/TopBar";
import { Label, Label14 } from "../../../components/Typography";
import { useElementaries } from "../../../state/elementaries";
import { useInputs, useInputsLoader } from "../../../state/inputs";
import { useActiveManufacturer } from "../../../state/manufacturers";
import { useActivePlant } from "../../../state/plants";
import { useSuppliers, useSuppliersLoader } from "../../../state/suppliers";
import { showErrorToast, showSuccessToast } from "../../../util/toasts";

const useMaterialBulkUpload = ({
  refetchMaterials,
  refetchSuppliers,
}: {
  refetchMaterials: () => Promise<void>;
  refetchSuppliers: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const bulkUploadMaterials = useBulkUploadMaterials();
  const [loading, setLoading] = useState(false);

  const onChange: (files: FileList | null) => void = useCallback(
    async (files) => {
      if (!activeManufacturer?.id || !activePlant?.id) {
        return;
      }

      const file = files?.[0];
      if (file && activeManufacturer?.id && activePlant?.id) {
        setLoading(true);

        try {
          await bulkUploadMaterials({
            manufacturerId: activeManufacturer.id,
            plantId: activePlant.id,
            file,
          });

          await Promise.all([refetchMaterials(), refetchSuppliers()]);
          setLoading(false);
          showSuccessToast(t("Materials uploaded successfully"));
        } catch (_) {
          showErrorToast(t("Couldn't upload materials, please try again."));
          setLoading(false);
        }
      }
    },
    [
      t,
      activeManufacturer?.id,
      activePlant?.id,
      bulkUploadMaterials,
      refetchMaterials,
      refetchSuppliers,
    ],
  );

  return { loading, onChange };
};

export const MaterialsList = () => {
  const navigate = useNavigate();
  const { refetch: refetchSuppliers } = useSuppliersLoader();
  const { refetch: refetchMaterials } = useInputsLoader();

  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useInputs();

  const { suppliers } = useSuppliers();

  const { loading: uploadLoading, onChange } = useMaterialBulkUpload({
    refetchMaterials,
    refetchSuppliers,
  });

  const input = useMemo(
    () => (
      <>
        <FileTrigger onSelect={onChange}>
          <Button intent="secondary">
            {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
            {t("Upload Materials")}
          </Button>
        </FileTrigger>
        <DialogTrigger>
          <Button>
            <AddOutlined />
            {t("New Material")}
          </Button>
          <Popover>
            <div className="flex flex-col gap-2">
              <Link
                intent="transparent"
                onPress={() => navigate("suppliers-and-materials/new-raw-material")}
              >
                <AddOutlined />
                {t("Add Material")}
              </Link>
              <Link
                intent="transparent"
                onPress={() => navigate("suppliers-and-materials/new-packaging")}
              >
                <AddOutlined />
                {t("Add Packaging Material")}
              </Link>
              <Link
                intent="transparent"
                onPress={() => navigate("suppliers-and-materials/new-prechain-product")}
              >
                <AddOutlined />
                {t("Add Own Product")}
              </Link>
            </div>
          </Popover>
        </DialogTrigger>
      </>
    ),
    [navigate, onChange, t, uploadLoading],
  );

  return (
    <PageContainer>
      <TopBar
        title={t("Materials")}
        subtitle={
          <>
            {rawMaterials.length} {t("materials", { count: rawMaterials.length })},{" "}
            {suppliers.length} {t("suppliers", { count: suppliers.length })}
          </>
        }
        icon={<TokenOutlined />}
        input={[...rawMaterials, ...packagingMaterials].length > 0 ? input : undefined}
      />
      <div className="flex-grow flex flex-col gap-4 py-8">
        {[...rawMaterials, ...packagingMaterials].length === 0 && (
          <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
            <div className="flex flex-col items-center gap-3">
              <Label className="flex items-center gap-2">{t("No materials added yet")}</Label>
              <div className="flex items-center gap-5">{input}</div>
            </div>
          </div>
        )}
        {rawMaterials.length > 0 && (
          <MaterialDataGrid inputs={rawMaterials} header={t("Material")} />
        )}
        {packagingMaterials.length > 0 && (
          <MaterialDataGrid inputs={packagingMaterials} header={t("Packaging")} />
        )}
      </div>
    </PageContainer>
  );
};

const MaterialDataGrid = ({
  inputs: materials,
  header,
}: {
  inputs: PlantInput[];
  header: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { elementaries } = useElementaries();

  const getRow = (input: PlantInput) => {
    if (input.supplier_product) {
      const item = input.supplier_product;
      return {
        id: item.id,
        category: elementaries.find((c) => c.id === item.elementary_id)?.name_en,
        productName: item.name,
        supplierCompany: item.supplier_plant?.supplier_company.name,
        plant: item.supplier_plant?.name,
        epdLinked: !!item.epd_id,
        isPrechainProduct: false,
      };
    }

    if (!input.prechain_product) {
      throw new Error("Invalid input");
    }

    const item = input.prechain_product;
    return {
      id: item.id,
      category: elementaries.find((c) => c.id === item.product.elementary_id)?.name_en,
      productName: item.product.name,
      supplierCompany: item.product.manufacturer.name,
      plant: item.product.plant.name,
      epdLinked: false,
      isPrechainProduct: true,
    };
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "category",
        headerName: header,
        width: 200,
      },
      {
        field: "productName",
        headerName: t("Name"),
        width: 300,
      },
      {
        field: "supplierCompany",
        headerName: t("Supplier"),
        width: 350,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "plant",
        headerName: t("Supplier Plant"),
        width: 200,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "epdLinked",
        headerName: t("EPD linked"),
        width: 200,
        align: "center",
        // use a custom component for this one
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center">
            {row.isPrechainProduct ? (
              <div className="flex items-center gap-1">
                <Commit fontSize="small" className="-translate-x-0.5" />
                <Label14>{t("Own Product")}</Label14>
              </div>
            ) : (
              <StatusIndicator
                text={value ? "EPD" : t("Secondary Data")}
                done={!!value}
                missing={!value}
              />
            )}
          </div>
        ),
      },
    ];
  }, [t, header]);

  return (
    <>
      <div className="max-h-[80vh] flex flex-col gap-1">
        <DataGrid
          className="h-full bg-white"
          rows={materials.map(getRow)}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          rowHeight={35}
          pageSizeOptions={[10]}
          onRowClick={(params: GridRowParams<{ id: string; isPrechainProduct: boolean }>) => {
            if (params.row.isPrechainProduct) {
              navigate(`/suppliers-and-materials/edit-prechain-product/${params.row.id}`);
            } else {
              navigate(`/suppliers-and-materials/edit/${params.row.id}`);
            }
          }}
        />
      </div>
    </>
  );
};
