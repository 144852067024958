import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetSupplierPlants, useGetSuppliers } from "../api/endpoints/suppliers";
import { Supplier } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

const initialState = {
  initialized: false,
  setInitialized: () => {},
  suppliers: [],
  setSuppliers: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [initialized, setInitialized] = useState(false);

  return {
    suppliers,
    setSuppliers,
    initialized,
    setInitialized,
  };
};

export const SuppliersProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useSuppliers = () => {
  return useContext(Context);
};

export const useSuppliersLoader = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const { setSuppliers, setInitialized } = useSuppliers();

  const getSuppliers = useGetSuppliers();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  const load = useCallback(async () => {
    if (!activeManufacturer || !activePlant) return;
    loading.current = true;
    try {
      const result = await getSuppliers({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
      });
      setSuppliers(result);
    } catch (e) {
      console.error(e);
    }
    loading.current = false;
    setInitialized(true);
  }, [activeManufacturer, activePlant, getSuppliers, setInitialized, setSuppliers]);

  useEffect(() => {
    if (!loading.current) {
      load();
    }
  }, [load]);

  return { refetch: load };
};

export const useLoadSupplierPlants = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  const getSupplierPlants = useGetSupplierPlants();

  return useCallback(
    (supplierId: string) => {
      if (!activeManufacturer || !activePlant) return Promise.resolve([]);

      return getSupplierPlants({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        supplierId,
      });
    },
    [activeManufacturer, activePlant, getSupplierPlants],
  );
};
