import tw from "tailwind-styled-components";
import loading from "../assets/loading.svg";

const Container = tw("div")`
  absolute
  flex
  justify-center
  items-center
  w-screen
  h-screen
  inset-x-0
  inset-y-0
`;

export const Loading = () => (
  <Container>
    <img className="w-10 h-10" src={loading} alt="Loading" />
  </Container>
);
