import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useGetElementaries } from "../api/endpoints/elementaries";
import { Elementary } from "../api/types";

const initialState = {
  initialized: false,
  setInitialized: () => {},
  elementariesMap: {},
  elementaries: [],
  setElementaries: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [elementaries, setElementaries] = useState<Elementary[]>([]);
  const [initialized, setInitialized] = useState(false);

  const elementariesMap = useMemo(() => {
    return Object.fromEntries(elementaries.map((item) => [item.id, item]));
  }, [elementaries]);

  return {
    elementariesMap,
    elementaries,
    setElementaries,
    initialized,
    setInitialized,
  };
};

export const ElementariesProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useElementaries = () => {
  return useContext(Context);
};

export const useElementariesLoader = () => {
  const { setElementaries, setInitialized } = useElementaries();

  const getElementaries = useGetElementaries();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  useEffect(() => {
    async function load() {
      loading.current = true;
      try {
        const result = await getElementaries();
        setElementaries(result);
      } catch (e) {
        console.error(e);
      }
      loading.current = false;
      setInitialized(true);
    }

    if (!loading.current) {
      load();
    }
  }, [getElementaries, setInitialized, setElementaries]);
};
