import { useCallback } from "react";
import { BulkUploadResult, Plant } from "../types";
import { useApi } from "../useApi";

export const useGetPlants = () => {
  const api = useApi<Plant[]>({
    method: "GET",
  });

  return useCallback(
    ({ manufacturerId }: { manufacturerId: string }) => {
      return api({ url: `/v1/manufacturers/${manufacturerId}/plants` });
    },
    [api],
  );
};

export const usePostPlant = () => {
  const api = useApi<Plant>({
    method: "POST",
  });

  return useCallback(
    ({
      manufacturerId,
      plant,
    }: {
      manufacturerId: string;
      plant: Omit<Plant, "id" | "input_output_schema" | "waste_treatment_schema">;
    }) => api({ url: `/v1/manufacturers/${manufacturerId}/plants`, data: plant }),
    [api],
  );
};

export const usePatchPlant = () => {
  const api = useApi<Plant>({
    method: "PUT",
  });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      plant,
    }: {
      manufacturerId: string;
      plantId: string;
      plant: Plant;
    }) => api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}`, data: plant }),
    [api],
  );
};

export const useDeletePlant = () => {
  const api = useApi<Plant>({
    method: "DELETE",
  });

  return useCallback(
    ({ manufacturerId, plantId }: { manufacturerId: string; plantId: string }) =>
      api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}` }),
    [api],
  );
};

export const useBulkUploadPlants = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ manufacturerId, file }: { manufacturerId: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/bulk`,
        data: formData,
      });
    },
    [api],
  );
};
