import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { COUNTRY_OPTIONS } from "../../util/countries";

export interface ManagePlantFieldsT {
  name: string;
  city: string;
  country: string;
}

export const ManagePlantFields = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-6">
      <TextFieldConnected name="name" label={t("Plant Name")} isRequired />
      <TextFieldConnected name="city" label={t("City")} isRequired />
      <ComboBoxFieldConnected
        name="country"
        isRequired
        options={COUNTRY_OPTIONS}
        label={t("Country")}
      />
    </div>
  );
};
