import { VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import { Link as LinkPrimitive, LinkProps } from "react-aria-components";
import { buttonStyles } from "./Button";

export const Link = ({
  intent,
  size,
  square,
  ...props
}: Omit<LinkProps, "className" | "styles" | "children"> &
  VariantProps<typeof buttonStyles> & {
    children?: ReactNode;
  }) => {
  return (
    <LinkPrimitive
      className={buttonStyles({ intent, size, square, isDisabled: props.isDisabled })}
      {...props}
    />
  );
};
