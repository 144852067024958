import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { v4 as uuidv4 } from "uuid";
import { DataGrid } from "../../components/DataGrid";

const MOCK_DATA_RESULTS = [
  {
    id: uuidv4(),
    indicator: "Global Warming Potential - total (GWP)",
    unit: "kg CO2eq",
    score: 75,
    a: 135.9,
    b: -3.94,
    c: 15.8,
    d: -9.4,
  },
  {
    id: uuidv4(),
    indicator: "Global Warming Potential - fossil (GWP)",
    unit: "kg CO2eq",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Global Warming Potential - biogenic (GWP)",
    unit: "kg CO2eq",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Global Warming Potential - LULUC (GWP)",
    unit: "kg CO2eq",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Use of net fresh waster (FW)",
    unit: "kg",
    score: 65,
    a: 0.4849,
    b: 0,
    c: 0.02,
    d: 0.1159,
  },
  {
    id: uuidv4(),
    indicator: "Materials for recycling (MFR)",
    unit: "kg",
    score: 52,
    a: 3.646,
    b: 0,
    c: 2279,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Biodiversity (BD)",
    unit: "-",
    score: 23,
    a: 5,
    b: 18,
    c: 34,
    d: 2,
  },
  {
    id: uuidv4(),
    indicator: "Circularity Score (CS)",
    unit: "-",
    score: 78,
    a: 3,
    b: 45,
    c: 32,
    d: 1,
  },
  {
    id: uuidv4(),
    indicator: "Depletion potential of the stratospheric ozone layer",
    unit: "kg CFC11e",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Acidification potential, Accumulated Exceedance",
    unit: "kg Pe",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Eutrophication aquatic freshwater",
    unit: "kg Pe",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Eutrophication aquatic marine",
    unit: "kg N eq.",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Eutrophication terrestrial",
    unit: "mol N eq.",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Formation potential of tropospheric ozone",
    unit: "kg NMVOC eq.",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Abiotic depletion potential (ADP-elements) ",
    unit: "kg Sbe",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
  {
    id: uuidv4(),
    indicator: "Abiotic depletion potential (ADP-fossil fuels)",
    unit: "MJ",
    score: 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
  },
];

const ColoredScore = tw("div")<{
  $green?: boolean;
  $yellow?: boolean;
  $red?: boolean;
  $gray?: boolean;
}>`
  ${({ $green }) => $green && "bg-green-100 text-green-500"}
  ${({ $yellow }) => $yellow && "bg-amber-100 text-amber-500"}
  ${({ $red }) => $red && "bg-rose-100 text-rose-500"}
  ${({ $gray }) => $gray && "bg-gray-100 text-gray-500"}
  rounded-full
  py-1 px-2
  w-10
  flex
  items-center
  justify-center
`;

const getScoreColor = (score: number) => {
  const green = score >= 65;
  const yellow = score >= 35 && score < 65;
  const red = score > 0 && score < 35;
  const gray = score === 0;

  return {
    green,
    yellow,
    red,
    gray,
  };
};

export const Results = () => {
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "indicator",
        headerName: "Indicator",
        width: 400,
        sortable: false,
      },
      {
        field: "unit",
        headerName: "Unit",
        width: 200,
      },
      {
        field: "score",
        headerName: "Emidat Score",
        type: "number",
        width: 110,
        renderCell: (params) => {
          const { green, yellow, gray, red } = getScoreColor(params.value as number);

          return (
            <ColoredScore $green={green} $yellow={yellow} $red={red} $gray={gray}>
              {params.value}
            </ColoredScore>
          );
        },
      },
      {
        field: "a",
        headerName: "A1-5",
        width: 100,
      },
      {
        field: "b",
        headerName: "B1-7",
        width: 150,
      },
      {
        field: "c",
        headerName: "C1-4",
        width: 150,
      },
      {
        field: "d",
        headerName: "D",
        sortable: false,
        width: 160,
      },
    ];
  }, []);

  return (
    <div>
      <DataGrid
        className="h-full bg-white"
        rows={MOCK_DATA_RESULTS.map((result) => ({
          ...result,
        }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        rowHeight={35}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </div>
  );
};
