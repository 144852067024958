import { useTranslation } from "react-i18next";
import { Text16 } from "../components/Typography";
import { ManageManufacturers } from "../page-components/company-settings/ManageManufacturers";
import { ManageProducts } from "../page-components/products/ManageProducts";
import { useActiveManufacturer } from "../state/manufacturers";
import { useActivePlant } from "../state/plants";
import { AddPlant } from "./EditOrAddPlant";

export const Products = () => {
  const { t } = useTranslation();
  const { activeManufacturer, initialized } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  if (!initialized) return null;

  if (!activeManufacturer) {
    return (
      <div className="flex flex-col gap-8 py-8">
        <Text16>
          {t("No production plants found. You'll have to create a production plant first.")}
        </Text16>
        <ManageManufacturers />
      </div>
    );
  }

  if (!activePlant) {
    return <AddPlant />;
  }

  return <ManageProducts />;
};
