import { useTranslation } from "react-i18next";
import { Heading2, Text16 } from "../components/Typography";

export const EPDTool = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading2>{t("EPD Tool")}</Heading2>
      <Text16>{t("Coming soon!")}</Text16>
    </>
  );
};
