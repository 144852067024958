import { cva } from "class-variance-authority";
import { forwardRef, ReactNode } from "react";
import { Input as InputPrimitive, InputProps } from "react-aria-components";
import { exists } from "../util/commonUtil";

const containerStyles = cva(
  [
    "flex items-center gap-4",
    "relative",
    "w-full",
    "border-b",
    "border-dotted",
    "border-neutral-300 has-[input:focus]:border-builtgreen",
    "text-builtgreen",
  ],
  {
    variants: {
      hasError: {
        true: "border-red-500 has-[input:focus]:border-red-500",
      },
      align: {
        left: "text-left",
        right: "text-right",
      },
      size: {
        medium: "text-base font-normal",
        large: "text-[2rem] font-bold",
      },
      isDisabled: {
        true: "cursor-not-allowed opacity-50 border-transparent",
      },
    },
    defaultVariants: {
      align: "left",
      size: "medium",
    },
  },
);

const addonLeftStyles = cva(
  ["absolute", "left-0", "top-1/2", "-translate-y-1/2", "pointer-events-none"],
  {
    variants: {
      pale: {
        true: "text-neutral-400",
      },
    },
  },
);

const addonRightStyles = cva(["pointer-events-none", "shrink-0"], {
  variants: {
    pale: {
      true: "text-neutral-400",
    },
  },
});

const inputStyles = cva(
  ["w-full", "outline-none", "flex-grow", "bg-transparent", "placeholder-neutral-400"],
  {
    variants: {
      hasAddonLeft: {
        true: "pl-8",
      },
    },
  },
);

export const Input = forwardRef<
  HTMLInputElement,
  Omit<InputProps, "className" | "size"> & {
    hasError?: boolean;
    addonLeft?: ReactNode;
    addonRight?: ReactNode;
    align?: "left" | "right";
    size?: "medium" | "large";
  }
>(({ align, addonLeft, addonRight, hasError, size, disabled, ...props }, ref) => {
  const hasValue = exists(props.value);

  return (
    <div
      className={containerStyles({
        hasError,
        align,
        size,
        isDisabled: disabled,
      })}
    >
      {addonLeft && (
        <span className={addonLeftStyles({ pale: disabled || !hasValue })}>{addonLeft}</span>
      )}
      <InputPrimitive
        className={inputStyles({
          hasAddonLeft: !!addonLeft,
        })}
        ref={ref}
        disabled={disabled}
        {...props}
      />
      {addonRight && (
        <span className={addonRightStyles({ pale: disabled || !hasValue })}>{addonRight}</span>
      )}
    </div>
  );
});
