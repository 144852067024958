import { AddOutlined, SaveOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchManufacturer, usePostManufacturer } from "../../api/endpoints/manufacturers";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useManufacturers } from "../../state/manufacturers";
import { COUNTRY_OPTIONS } from "../../util/countries";

interface Fields {
  name: string;
  email: string;
  phone: string;
  address: string;
  postCode: string;
  city: string;
  country: string;
}

// TODO: Get the complexity down
// eslint-disable-next-line complexity
export const ManageManufacturers = () => {
  const { t } = useTranslation();
  const { activeManufacturer, setActiveManufacturer } = useManufacturers();

  const methods = useForm<Fields>({
    defaultValues: {
      name: activeManufacturer?.name || undefined,
      email: activeManufacturer?.email || undefined,
      phone: activeManufacturer?.phone || undefined,
      address: activeManufacturer?.address || undefined,
      postCode: activeManufacturer?.post_code || undefined,
      city: activeManufacturer?.city || undefined,
      country: activeManufacturer?.country || undefined,
    },
  });

  const { reset } = methods;
  const [prevActiveManufacturer, setPrevActiveManufacturer] = useState(activeManufacturer);
  useEffect(() => {
    if (prevActiveManufacturer !== activeManufacturer) {
      setPrevActiveManufacturer(activeManufacturer);
      if (activeManufacturer) {
        reset({
          name: activeManufacturer.name,
          email: activeManufacturer.email,
          phone: activeManufacturer.phone,
          address: activeManufacturer.address,
          postCode: activeManufacturer.post_code,
          city: activeManufacturer.city,
          country: activeManufacturer.country,
        });
      }
    }
  }, [prevActiveManufacturer, setPrevActiveManufacturer, activeManufacturer, reset]);

  const [loading, setLoading] = useState(false);

  const postManufacturer = usePostManufacturer();
  const patchManufacturer = usePatchManufacturer();
  const onSubmit: SubmitHandler<Fields> = async ({ postCode, ...data }) => {
    try {
      setLoading(true);
      if (activeManufacturer) {
        const result = await patchManufacturer({
          manufacturerId: activeManufacturer.id,
          manufacturer: {
            ...activeManufacturer,
            ...data,
            post_code: postCode,
            website: "",
          },
        });
        setActiveManufacturer(result);
      } else {
        const result = await postManufacturer({
          ...data,
          post_code: postCode,
          website: "",
        });
        setActiveManufacturer(result);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const headline = activeManufacturer ? t("Company Address") : t("Create Company");

  return (
    <Card title={headline}>
      <FormProvider {...methods}>
        <form
          className="grid grid-cols-2 gap-6"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <TextFieldConnected name="name" label={t("Company Name")} isRequired />
          <TextFieldConnected
            name="email"
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("Invalid email address"),
              },
            }}
            isRequired
            type="email"
            label={t("Email")}
          />
          <TextFieldConnected name="phone" isRequired type="tel" label={t("Phone")} />
          <TextFieldConnected name="address" isRequired label={t("Address")} />
          <div className="grid grid-cols-[30%,1fr] gap-3 ">
            <TextFieldConnected name="postCode" isRequired label={t("Zip Code")} />
            <TextFieldConnected name="city" isRequired label={t("City")} />
          </div>
          <ComboBoxFieldConnected
            name="country"
            isRequired
            options={COUNTRY_OPTIONS}
            label={t("Country")}
          />
          <div className="col-span-2 flex justify-end">
            <Button type="submit" isDisabled={loading}>
              {loading && <CircularProgress size="24px" />}
              {!activeManufacturer ? <AddOutlined /> : <SaveOutlined />}
              {!activeManufacturer ? t("Create company") : t("Update company")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
