import { Close } from "@mui/icons-material";
import { ReactNode } from "react";
import { Dialog, DialogProps, ModalOverlay, Modal as ModalPrimitive } from "react-aria-components";
import tw from "tailwind-styled-components";
import { Button } from "./Button";
import { Heading4 } from "./Typography";

const SxModalOverlay = tw(ModalOverlay)`
  fixed
  inset-0
  z-[100]
  flex items-start justify-center
  min-h-full
  overflow-y-auto
  bg-slate-900
  bg-opacity-60
  p-8
  text-center
  backdrop-blur-sm
`;

export const Modal = ({ children }: Pick<DialogProps, "children">) => {
  return (
    <SxModalOverlay>
      <ModalPrimitive className="w-full rounded-lg border bg-white text-left max-w-lg shadow-2xl">
        <Dialog className="flex flex-col outline-none">{children}</Dialog>
      </ModalPrimitive>
    </SxModalOverlay>
  );
};

export const ModalHeader = ({ title, onClose }: { title: string; onClose?: () => void }) => (
  <div className="flex items-center justify-between border-b p-6">
    <Heading4>{title}</Heading4>
    {onClose && (
      <Button intent="transparent" onPress={onClose}>
        <Close />
      </Button>
    )}
  </div>
);

export const ModalBody = ({ children }: { children: ReactNode }) => (
  <div className="bg-white p-6">{children}</div>
);

export const ModalFooter = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center gap-4 justify-end border-t p-6">{children}</div>
);
