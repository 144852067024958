import { Clear } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { usePostProductionProcessForPlant } from "../../api/endpoints/process-flows";
import { ProductCategory, ProductionProcessTemplate } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { Heading4 } from "../../components/Typography";
import { useActiveManufacturer, useActiveProductCategories } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import {
  useProductionProcessTemplatesLoader,
  useProductionProcesses,
} from "../../state/processFlows";
import { RenderedFlow } from "../product-detail/RenderedFlow";

const useSubmitProductionProcess = ({
  selectedProcessTemplateId,
}: {
  selectedProcessTemplateId: string | null;
}) => {
  const navigate = useNavigate();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const postProductionProcessForPlant = usePostProductionProcessForPlant();

  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    if (!activeManufacturer || !activePlant || !selectedProcessTemplateId) return;

    setLoading(true);
    try {
      await postProductionProcessForPlant({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        productionProcessTemplateId: selectedProcessTemplateId,
      });
      setLoading(false);
      navigate("/production");
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [
    activeManufacturer,
    activePlant,
    navigate,
    postProductionProcessForPlant,
    selectedProcessTemplateId,
  ]);

  return {
    loading,
    onSubmit,
  };
};

export const NewProductionProcess = () => {
  useProductionProcessTemplatesLoader();

  const { productionProcessTemplates } = useProductionProcesses();

  const { productCategoriesMap } = useActiveProductCategories();

  const { t } = useTranslation();
  const [selectedProcessTemplateId, setSelectedProcessTemplateId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { loading, onSubmit } = useSubmitProductionProcess({ selectedProcessTemplateId });

  return (
    <PageContainer
      $as="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TopBar
        title={t("New Production Process")}
        input={
          <Button intent="transparent" size="small" onPress={() => navigate("/production")}>
            {t("Cancel process")}
            <Clear />
          </Button>
        }
      />
      <div className="flex flex-col gap-6 py-8">
        {productionProcessTemplates.map((process) => (
          <ProductionProcessItem
            category={productCategoriesMap[process.category_id]}
            key={process.name}
            isActive={selectedProcessTemplateId === process.id}
            onSelectItem={setSelectedProcessTemplateId}
            item={process}
          />
        ))}
        <FormActions
          onPrev={() => {
            navigate("/production");
          }}
          nextDisabled={!selectedProcessTemplateId}
          nextLoading={loading}
        />
      </div>
    </PageContainer>
  );
};

const FormActions = ({
  onPrev,
  onNext,
  nextDisabled,
  nextLoading,
}: {
  onPrev?: () => void;
  onNext?: () => void;
  nextDisabled?: boolean;
  nextLoading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center gap-4">
      <Button type="button" intent="secondary" onPress={onPrev}>
        {t("Back")}
      </Button>
      <Button onPress={onNext} type="submit" isDisabled={nextDisabled || nextLoading}>
        {t("Submit")}
        {nextLoading && <CircularProgress size="20px" />}
      </Button>
    </div>
  );
};

const ItemContainer = tw("div")<{ $active?: boolean; $disabled?: boolean }>`
  bg-white
  rounded-2xl
  border
  p-8
  space-y-3
  ${({ $active }) =>
    $active
      ? "border-energyyellow hover:border-energyyellow shadow-lg hover:shadow-lg"
      : "hover:border-energyyellow hover:shadow"}
  ${({ $disabled }) =>
    $disabled
      ? "opacity-70 cursor-not-allowed hover:border-gray-200 hover:shadow-none"
      : "cursor-pointer"}
`;

const ProductionProcessItem = ({
  item,
  category,
  isActive,
  isDisabled,
  onSelectItem,
}: {
  item: ProductionProcessTemplate;
  category?: ProductCategory;
  isActive: boolean;
  isDisabled?: boolean;
  onSelectItem: (id: string | null) => void;
}) => {
  return (
    <ItemContainer
      $active={isActive}
      $disabled={isDisabled}
      onClick={() => onSelectItem(isDisabled ? null : item.id)}
    >
      <div className="flex justify-between items-center">
        <Heading4>{item.name}</Heading4>
        <Badge>{category?.name.en}</Badge>
      </div>
      <RenderedFlow flow={item} />
    </ItemContainer>
  );
};
