import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetMyProfile, useGetProfiles } from "../api/endpoints/profiles";
import { Profile } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";

const initialState = {
  profiles: [],
  setProfiles: () => {},
  myProfile: null,
  setMyProfile: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [myProfile, setMyProfile] = useState<Profile | null>(null);

  return {
    profiles,
    setProfiles,
    myProfile,
    setMyProfile,
  };
};

export const ProfilesProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useProfiles = () => {
  return useContext(Context);
};

export const useProfilesLoader = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { setProfiles } = useProfiles();

  const getProfiles = useGetProfiles();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  const load = useCallback(async () => {
    if (!activeManufacturer) return;
    loading.current = true;
    try {
      const result = await getProfiles({
        manufacturerId: activeManufacturer.id,
      });
      setProfiles(result);
    } catch (e) {
      console.error(e);
    }
    loading.current = false;
  }, [activeManufacturer, setProfiles, getProfiles]);

  useEffect(() => {
    if (!loading.current) {
      load();
    }
  }, [load]);

  return { refetch: load };
};

export const useMyProfileLoader = () => {
  const { setMyProfile } = useProfiles();

  const getMyProfile = useGetMyProfile();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  const load = useCallback(async () => {
    loading.current = true;
    try {
      const result = await getMyProfile();
      setMyProfile(result);
    } catch (e) {
      console.error(e);
    }
    loading.current = false;
  }, [setMyProfile, getMyProfile]);

  useEffect(() => {
    if (!loading.current) {
      load();
    }
  }, [load]);
};
