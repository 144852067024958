import { DataGrid as DataGridPrimitive } from "@mui/x-data-grid";
import { ComponentProps } from "react";

export const DataGrid = (props: ComponentProps<typeof DataGridPrimitive>) => {
  return (
    <DataGridPrimitive
      {...props}
      rowHeight={40}
      sx={{
        borderRadius: 4,
        overflow: "hidden",
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler": {
          backgroundColor: "#F5F5F5",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
      }}
    />
  );
};
