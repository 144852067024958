import {
  Business,
  Construction,
  Edit,
  FactoryOutlined,
  Search,
  SvgIconComponent,
  Terminal,
  TokenOutlined,
  WindowOutlined,
} from "@mui/icons-material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAdminMode } from "../state/admin";

export interface NavbarItemType {
  name: string;
  path: string;
  icon?: SvgIconComponent;
  children?: NavbarItemType[]; // Supporting a single level of children for now
  hideNavbar?: boolean;
  step?: number;
}

export const useAllPages = (): Record<string, NavbarItemType> => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      products: { name: t("Products"), path: "/", icon: WindowOutlined },
      plant: { name: t("Production"), path: "/production", icon: FactoryOutlined },
      materials: { name: t("Materials"), path: "/suppliers-and-materials", icon: TokenOutlined },
      companySettings: {
        name: t("Company Settings"),
        path: "/company-settings",
        icon: Business,
      },
      edit: {
        name: t("Edit"),
        path: "/edit",
        icon: Edit,
        hideNavbar: true,
      },
      editProductDetails: {
        name: t("Create a product profile"),
        path: "/edit/product-details",
        hideNavbar: true,
        step: 1,
      },
      editProductSpecs: {
        name: t("Create a product profile"),
        path: "/edit/product-specs",
        hideNavbar: true,
        step: 2,
      },
      editProductProductionProcess: {
        name: t("Choose a production process"),
        path: "/edit/product-production-process",
        hideNavbar: true,
        step: 3,
      },
      editProductMaterials: {
        name: t("Add recipe"),
        path: "/edit/product-recipe",
        hideNavbar: true,
        step: 4,
      },
      editProcessFlow: {
        name: t("Model Flow"),
        path: "/edit/process-flow",
        hideNavbar: true,
      },
      editProcessMaterials: {
        name: t("Model Materials"),
        path: "/edit/process-materials",
        hideNavbar: true,
      },

      // SPECIAL TOOL PAGES
      search: { name: t("Search"), path: "/search", icon: Search },
      apiAccess: { name: t("API Access"), path: "", icon: Terminal },
      epdTool: { name: t("EPD Tool"), path: "", icon: Construction },
      // ------

      // LEGACY
      editLCAResults: { name: t("LCA Results"), path: "/edit/results" },
      editVerification: { name: t("Document Verification"), path: "/edit/verification" },
      editPublication: { name: t("Document Publication"), path: "/edit/publication" },
      // ------
    }),
    [t],
  );
};

export const useNavbarItems = (): NavbarItemType[] => {
  const adminMode = useAdminMode();

  const pages = useAllPages();

  return useMemo(
    () => [
      pages.products,
      pages.plant,
      pages.materials,
      pages.search,
      ...(adminMode
        ? [
            {
              ...pages.edit,
              children: [
                pages.editProductDetails,
                pages.editProductSpecs,
                pages.editProductDetails,
                pages.editProcessFlow,
                pages.editProcessMaterials,
                pages.editLCAResults,
                pages.editVerification,
                pages.editPublication,
              ],
            },
          ]
        : []),
    ],
    [pages, adminMode],
  );
};
