import { useAuth0 } from "@auth0/auth0-react";
import Intercom from "@intercom/messenger-js-sdk";
import { ChatBubbleOutline, Logout, MenuOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useAllPages, useNavbarItems } from "../config/useNavbarItems";
import { useNavigation } from "../state/navigation";
import { useActivePlant } from "../state/plants";
import { IconContainer } from "./IconContainer";
import { ItemContainer, NavbarItem } from "./NavbarItem";
import { PlantSelector } from "./PlantSelector";

const Nav = tw("nav")<{ $collapsed?: boolean }>`
  flex-shrink-0
  h-full
  flex
  flex-col
  justify-between
  overflow-y-auto
  ${({ $collapsed }) => !$collapsed && "w-[var(--navbar-width)]"}
  bg-neutral-100
`;

export const Navbar = () => {
  const { t } = useTranslation();
  const { collapsed, setCollapsed, showNavigation } = useNavigation();

  const allPages = useAllPages();
  const activePath = useLocation().pathname;
  const navbarItems = useNavbarItems();

  const { activePlant } = useActivePlant();

  const [isPlantSelectorOpen, setPlantSelectorOpen] = useState(false);

  const { logout, user } = useAuth0();
  const logoutWithRedirect = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  useHotkeys("alt+shift+c", () => setCollapsed((c) => !c), [setCollapsed]);

  Intercom({
    app_id: "xoey0lpy",
    email: user?.email,
    id: user?.email,
    custom_launcher_selector: "#intercom_launcher",
    hide_default_launcher: showNavigation,
  });

  if (!showNavigation) return <div />;

  return (
    <Nav $collapsed={collapsed}>
      <div className="space-y-8">
        <div className="flex flex-col">
          <ItemContainer
            onClick={() => {
              setPlantSelectorOpen((o) => !o);
              setCollapsed(false);
            }}
            className="py-4"
          >
            <IconContainer $active>
              <MenuOutlined />
            </IconContainer>
            {!collapsed && activePlant?.name}
          </ItemContainer>
          <hr className="border-neutral-300 mb-5" />
          {isPlantSelectorOpen ? (
            <PlantSelector onClose={() => setPlantSelectorOpen(false)} collapsed={collapsed} />
          ) : (
            navbarItems.map((item, i) => {
              return (
                <NavbarItem
                  collapsed={collapsed}
                  key={`${i}-${item.path}`}
                  item={item}
                  activePath={activePath}
                />
              );
            })
          )}
        </div>
      </div>
      {!isPlantSelectorOpen && (
        <div className="flex flex-col">
          <ItemContainer id="intercom_launcher">
            <IconContainer $pale $bordered>
              <ChatBubbleOutline fontSize="small" />
            </IconContainer>
            {!collapsed && t("Support")}
          </ItemContainer>
          <NavbarItem
            collapsed={collapsed}
            item={allPages.companySettings}
            activePath={activePath}
            pale
          />
          <ItemContainer onClick={() => logoutWithRedirect()}>
            <IconContainer $pale $bordered>
              <Logout fontSize="small" />
            </IconContainer>
            {!collapsed && t("Logout")}
          </ItemContainer>
        </div>
      )}
    </Nav>
  );
};
