import { useCallback } from "react";
import { ProductionProcess, ProductionProcessTemplate } from "../types";
import { useApi } from "../useApi";

export const useGetProductionProcessTemplates = () => {
  const api = useApi<ProductionProcessTemplate[]>({ method: "GET" });

  return useCallback(
    () =>
      api({
        url: `/v1/production-processes/templates`,
      }),
    [api],
  );
};

export const usePostProductionProcessForPlant = () => {
  const api = useApi<ProductionProcess>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productionProcessTemplateId,
    }: {
      manufacturerId: string;
      plantId: string;
      productionProcessTemplateId: ProductionProcessTemplate["id"];
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/production-processes `,
        data: {
          template_id: productionProcessTemplateId,
        },
      }),
    [api],
  );
};

export const usePatchProcessFlow = () => {
  const api = useApi<ProductionProcess>({ method: "PUT" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      processFlow,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      processFlow: ProductionProcess;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/process-flows/${processFlow.id}`,
        data: processFlow,
      }),
    [api],
  );
};

export const usePostProcessFlow = () => {
  const api = useApi<ProductionProcess>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      processFlow,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      processFlow: ProductionProcess;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/process-flows`,
        data: processFlow,
      }),
    [api],
  );
};
