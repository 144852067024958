import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePatchProduct } from "../../api/endpoints/products";
import { Elementary, PlantInput, Product } from "../../api/types";
import { Label } from "../../components/Typography";
import {
  combineIds,
  ProductRecipeFields,
  splitIds,
} from "../../page-components/product-recipe/ProductRecipeFields";
import {
  ProductRecipeForm,
  ProductRecipeMaterial,
  ProductRecipeMaterialOption,
} from "../../page-components/product-recipe/types";
import {
  HARD_CODED_CONCRETE_CATEGORY_ID,
  useHardCodedConcreteMustContainValidation,
} from "../../state/HARD_CODED";
import { useElementaries } from "../../state/elementaries";
import { useInputs, useInputsLoader } from "../../state/inputs";
import { useManufacturers } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useSelectedProduct } from "../../state/products";
import { useSplash } from "../../state/splash";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";

const sumByCategories = (
  elementariesMap: Record<string, Elementary>,
  categories: string[],
  materials: ProductRecipeMaterial[],
): number => {
  return materials
    .filter((material) =>
      categories.includes(elementariesMap[material.elementaryId].category.toLowerCase()),
    )
    .map((material) => Number(material.value) || 0)
    .reduce((sum, val) => sum + val, 0);
};

const useErrorMessage = ({
  values,
  elementariesMap,
  selectedProduct,
}: {
  values: ProductRecipeForm;
  elementariesMap: Record<string, Elementary>;
  selectedProduct?: Product;
}) => {
  const { t } = useTranslation();
  const valueIsValid = (value?: unknown) => {
    // value is a string due to the TextField component
    // ideally: value should be a number right away in the form state
    return exists(value) && !isNaN(Number(value)) && Number(value) > 0;
  };

  const totalMass = useMemo(() => {
    if (!values.materials) return 0;

    return values.materials
      .map((material) => Number(material.value) || 0)
      .reduce((sum, val) => sum + val, 0);
  }, [values]);

  const concreteMustContainValidation = useHardCodedConcreteMustContainValidation();

  return useMemo(() => {
    if (!selectedProduct?.mass) {
      return "Initializing ..";
    }

    if (selectedProduct.category.id === HARD_CODED_CONCRETE_CATEGORY_ID) {
      for (const ingredient of concreteMustContainValidation) {
        const categoryMass = sumByCategories(
          elementariesMap,
          ingredient.fromCategories,
          values.materials,
        );

        if (categoryMass === 0) {
          return t("Recipe must contain {{ label }}.", { label: ingredient.label });
        }
      }
    }

    if (
      !(
        values.materials.length > 0 &&
        values.materials.every(({ value }) => valueIsValid(value)) &&
        values.materials.every((x) => exists(x.input))
      )
    ) {
      return t("All materials and masses must be filled.");
    }

    if (selectedProduct.mass > totalMass) {
      return t(
        "Mass of ingredients ({{ recipeMass }} kg) is lower than mass of the product ({{ productMass }} kg).",
        {
          productMass: selectedProduct.mass,
          recipeMass: totalMass,
        },
      );
    }

    return null;
  }, [t, values, elementariesMap, totalMass, selectedProduct, concreteMustContainValidation]);
};

const useMaterialOptions = ({
  selectedProduct,
  rawMaterials,
}: {
  selectedProduct?: Product;
  rawMaterials: PlantInput[];
}) => {
  const { elementaries } = useElementaries();

  function compare(a: ProductRecipeMaterialOption, b: ProductRecipeMaterialOption) {
    if (a.isAllowedInCategory === b.isAllowedInCategory) {
      return a.label.localeCompare(b.label);
    } else if (a.isAllowedInCategory) {
      return -1;
    } else return 1;
  }

  const data: ProductRecipeMaterialOption[] = useMemo(() => {
    if (!elementaries || !selectedProduct) return [];

    return elementaries
      .filter((elementary) =>
        rawMaterials.some(
          (material) =>
            material.supplier_product?.elementary_id === elementary.id ||
            material.prechain_product?.product?.elementary_id === elementary.id,
        ),
      )
      .map((elementary) => ({
        elementaryId: elementary.id,
        label: elementary.name_en,
        unit: "kg",
        isAllowedInCategory: elementary.product_categories.includes(selectedProduct.category.id),
      }))
      .sort(compare);
  }, [elementaries, selectedProduct, rawMaterials]);

  return data;
};

const useSubmitRecipe = ({ selectedProduct }: { selectedProduct?: Product }) => {
  const { t } = useTranslation();
  const { setSplash } = useSplash();
  const { activeManufacturer } = useManufacturers();
  const { activePlant } = useActivePlant();
  const patchProduct = usePatchProduct();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<ProductRecipeForm> = useCallback(
    async (fields) => {
      if (!activeManufacturer || !selectedProduct || !activePlant) {
        return;
      }

      setLoading(true);
      try {
        await patchProduct({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          productId: selectedProduct.id,
          product: {
            ...selectedProduct,
            recipe: fields.materials.map(({ elementaryId, value, input }) => {
              const { supplierProductId, prechainProductId } = splitIds(input);

              return {
                product_id: selectedProduct.id,
                elementary_id: elementaryId,
                mass: Number(value),
                supplier_product_id: supplierProductId ?? null,
                prechain_product_id: prechainProductId ?? null,
              };
            }),
          },
        });
        setLoading(false);

        navigate({ pathname: `/products/${selectedProduct.id}` });
        setSplash({ message: t("New product created") });
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [activeManufacturer, activePlant, navigate, patchProduct, selectedProduct, setSplash, t],
  );

  return {
    loading,
    onSubmit,
  };
};

export const ProductRecipe = () => {
  useInputsLoader();
  const { t } = useTranslation();

  const { elementariesMap } = useElementaries();

  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const selectedProduct = useSelectedProduct();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-production-process"));

  const { rawMaterials } = useInputs();
  const materialOptions = useMaterialOptions({ selectedProduct, rawMaterials });

  const methods = useForm<ProductRecipeForm>({
    defaultValues: {
      materials: selectedProduct?.recipe
        ? selectedProduct.recipe.map((item) => {
            return {
              elementaryId: item.elementary_id,
              label: elementariesMap[item.elementary_id]?.name_en,
              unit: "kg",
              value: item.mass,
              input: `${item.supplier_product_id}--${item.prechain_product_id}`,
            };
          })
        : [],
    },
  });

  useWarnBeforeUnload(methods.formState.isDirty);

  const values = methods.watch();

  const errorMessage = useErrorMessage({ values, elementariesMap, selectedProduct });

  const canGoNext = values.materials.length > 0 && !errorMessage;

  const reset = methods.reset;

  useEffect(
    function populateForm() {
      reset({
        materials: selectedProduct?.recipe
          ? selectedProduct.recipe.map((item) => {
              return {
                elementaryId: item.elementary_id,
                label: elementariesMap[item.elementary_id]?.name_en,
                unit: "kg",
                value: item.mass,
                input: combineIds({
                  supplierProductId: item.supplier_product_id,
                  prechainProductId: item.prechain_product_id,
                }),
              };
            })
          : [],
      });
    },
    [reset, materialOptions, elementariesMap, selectedProduct, rawMaterials],
  );

  const { loading, onSubmit } = useSubmitRecipe({
    selectedProduct,
  });

  return (
    <FormProvider {...methods}>
      <form
        className="flex-grow flex flex-col gap-5 overflow-hidden mx-auto w-full max-w-6xl pb-20"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProductRecipeFields materialOptions={materialOptions} inputs={rawMaterials} />
        <Label className="text-gray-500 self-end">
          {errorMessage ||
            t("By continuing, I confirm the correctness and completeness of my input")}
        </Label>
        <EditFlowNav onPrev={onPrev} nextSubmit nextDisabled={!canGoNext} nextLoading={loading} />
      </form>
    </FormProvider>
  );
};
