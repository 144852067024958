import { ResponsiveBar } from "@nivo/bar";
import { LciaResults, SourceImpacts, SourceType } from "../../api/types";
import { Text14 } from "../../components/Typography";
import { coreIndicators, filterImpact, mergeImpact } from "../../lca/impact";
import { formatPrecision } from "../../util/format";
import { useImpactColors } from "./useImpactColors";

export const ImpactsByElementaryTypeChart = ({ lcia }: { lcia: LciaResults }) => {
  const impactsPerSource = lcia.impacts_per_source;
  const total = lcia.impact_summary.a1_a3;
  const totalMap: Record<string, number> = total.reduce(
    (acc, impact) => ({ ...acc, [impact.indicator_name]: impact.value }),
    {},
  );

  const getData = (impactsPerSource: SourceImpacts[], types: SourceType[], category: string) => {
    const filtered = impactsPerSource.filter((impactPerSource) =>
      types.some((x) => x === impactPerSource.type),
    );
    const totalImapct = filterImpact(
      mergeImpact(...filtered.map((x) => x.impacts_total)),
      coreIndicators,
    );

    return {
      ...totalImapct.reduce(
        (acc, impact) => ({
          ...acc,
          [impact.indicator_name]: impact.value / Math.abs(totalMap[impact.indicator_name]),
          [impact.indicator_name + "_unit"]: impact.unit,
          elementaryType: category,
        }),
        {
          elementaryType: category,
        },
      ),
    };
  };

  const colors = useImpactColors();

  const groups = [
    getData(impactsPerSource, ["raw_materials"], "Raw Materials"),
    getData(impactsPerSource, ["energy_and_fuels"], "Production Energy"),
    getData(impactsPerSource, ["ancillary_materials"], "Ancillary Materials"),
    getData(impactsPerSource, ["waste"], "Waste"),
    getData(impactsPerSource, ["packaging"], "Packaging"),
  ];
  if (impactsPerSource.some((x) => x.type === "other")) {
    groups.push(getData(impactsPerSource, ["other"], "Other"));
  }

  return (
    <ResponsiveBar
      data={groups}
      keys={Array.from(coreIndicators)}
      indexBy="elementaryType"
      margin={{ top: 5, bottom: 25 }}
      padding={0.1}
      innerPadding={4}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={(x) => {
        return colors[x.id];
      }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickValues: [],
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      gridYValues={[-1, -0.5, 0, 0.5, 1]}
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      enableLabel={false}
      tooltip={({ value, id }) => {
        return (
          <div className="bg-white p-2 rounded-md shadow-md">
            <Text14>
              {id} <strong>{formatPrecision(value * 100, 4)}%</strong>
            </Text14>
          </div>
        );
      }}
    />
  );
};
