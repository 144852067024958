import { FactoryOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ManageCompanyLogo } from "../page-components/company-settings/ManageCompanyLogo";
import { ManageManufacturers } from "../page-components/company-settings/ManageManufacturers";
import { ManagePlants } from "./ManagePlants";
import { ManageUsers } from "./ManageUsers";

export const CompanySettings = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <TopBar title={t("Company Settings")} icon={<FactoryOutlined />} />
      <div className="flex flex-col gap-12 py-8">
        <ManageManufacturers />
        <ManageCompanyLogo />
        <ManagePlants />
        <ManageUsers />
      </div>
    </PageContainer>
  );
};
