import { useLocation } from "react-router-dom";
import { Elementary, PrechainProduct } from "../../api/types";
import { useInputsLoader, usePrechainProducts } from "../../state/inputs";
import { useMaterial } from "../../state/materials";
import { usePrechainProduct } from "../../state/prechainProducts";
import { Form } from "./suppliers-and-materials/Form";
import { MaterialsList } from "./suppliers-and-materials/MaterialsList";
import { PrechainProductForm } from "./suppliers-and-materials/PrechainProductForm";

export const ManageSuppliersAndMaterials = () => {
  const location = useLocation();
  const [last, secondLast] = location.pathname.split("/").reverse();
  const isNewRawMaterialPage = last === "new-raw-material";
  const isNewPackagingPage = last === "new-packaging";
  const isNewPrechainProductPage = last === "new-prechain-product";

  const isEditPage = secondLast === "edit";
  const materialId = isEditPage ? last : undefined;

  const isEditPrechainProductPage = secondLast === "edit-prechain-product";
  const prechainProductId = isEditPrechainProductPage ? last : undefined;

  useInputsLoader();
  const prechainProducts = usePrechainProducts();

  if (isNewPrechainProductPage)
    return <PrechainProductForm prechainProduct={null} existingProducts={prechainProducts} />;
  if (isEditPrechainProductPage && prechainProductId)
    return (
      <EditPrechainProductPage
        prechainProductId={prechainProductId}
        existingProducts={prechainProducts}
      />
    );

  if (isNewRawMaterialPage || isNewPackagingPage || isEditPage)
    return (
      <SupplierMaterialForm
        materialId={materialId}
        type={isNewRawMaterialPage ? "raw_materials" : isNewPackagingPage ? "packaging" : undefined}
      />
    );

  return <MaterialsList />;
};

const SupplierMaterialForm = ({
  materialId,
  type,
}: {
  materialId?: string;
  type?: Elementary["type"];
}) => {
  const existingMaterial = useMaterial(materialId);

  if (!!materialId && !existingMaterial) return null;

  return <Form existingMaterial={existingMaterial} type={type} />;
};

const EditPrechainProductPage = ({
  prechainProductId,
  existingProducts,
}: {
  prechainProductId: string;
  existingProducts: PrechainProduct[];
}) => {
  const prechainProduct = usePrechainProduct(prechainProductId);

  if (!prechainProduct) return null;

  return (
    <PrechainProductForm prechainProduct={prechainProduct} existingProducts={existingProducts} />
  );
};
