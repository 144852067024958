import { useAuth0 } from "@auth0/auth0-react";
import auth0, { Auth0UserProfile } from "auth0-js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { showErrorToast, showSuccessToast } from "../util/toasts";

const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientID = import.meta.env.VITE_AUTH0_CLIENT_ID;

export const scope = "email profile read:current_user update:current_user_metadata";

export const webAuth = new auth0.WebAuth({ domain, clientID });

// TODO: probably avoid using the Auth0 management API altogether
// and store every other user-related fact in our own database
// but until then, expose only the necessary methods
export const useManagementApi = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [authMgmt, setAuthMgmt] = useState<auth0.Management>();
  const { t } = useTranslation();

  useEffect(() => {
    async function initManagementApi() {
      const token = await getAccessTokenSilently({
        authorizationParams: { audience, scope },
      });
      const authManagement = new auth0.Management({ domain, token });

      setAuthMgmt(authManagement);
    }

    if (!authMgmt) {
      initManagementApi();
    }
  }, [authMgmt, getAccessTokenSilently]);

  const getUserData = useCallback(() => {
    if (!authMgmt) return;

    return new Promise<Auth0UserProfile>((resolve, reject) => {
      authMgmt.getUser(user?.sub || "", (err, res) => {
        if (res) {
          resolve(res);
        } else {
          console.error(err);
          reject(err);
        }
      });
    });
  }, [authMgmt, user?.sub]);

  const patchUserMetadata = useCallback(
    (userData: Record<string, unknown>) => {
      if (!authMgmt) return;

      authMgmt.patchUserMetadata(user?.sub || "", userData, (err) => {
        if (err) {
          showErrorToast(t("Something went wrong."));
        } else {
          showSuccessToast(t("Profile is updated."));
        }
      });
    },
    [authMgmt, t, user?.sub],
  );

  return {
    getUserData,
    patchUserMetadata,
  };
};

export const useUserData = () => {
  const { getUserData } = useManagementApi();
  const [userData, setUserData] = useState<Auth0UserProfile>();

  useEffect(() => {
    async function fetchUserData() {
      const data = await getUserData();
      setUserData(data);
    }

    if (!userData) {
      fetchUserData();
    }
  }, [getUserData, userData]);

  return userData;
};

export const useMaxPlantsCount = () => {
  const { user } = useAuth0();

  return user?.["app_metadata"]["max_plants"] || 3;
};
