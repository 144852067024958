import { cva } from "class-variance-authority";
import { Button as ButtonPrimitive } from "react-aria-components";
import { Label14 } from "./Typography";

const buttonStyles = cva(["outline-none", "rounded", "border", "bg-white", "py-1", "px-2"], {
  variants: {
    active: {
      true: "text-builtgreen bg-white",
      false: "text-neutral-400 bg-opacity-0 border-transparent",
    },
  },
});

export const TabSwitch = ({
  activeTab,
  setActiveTab,
  options,
}: {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  options: { label: string; value: string }[];
}) => {
  return (
    <div className="bg-neutral-100 rounded-lg p-1">
      {options.map(({ label, value }) => (
        <ButtonPrimitive
          className={buttonStyles({ active: value === activeTab })}
          key={value}
          onPress={() => setActiveTab(value)}
        >
          <Label14>{label}</Label14>
        </ButtonPrimitive>
      ))}
    </div>
  );
};
