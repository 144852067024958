import { useCallback } from "react";
import { Declaration, LciaResults, Reason } from "../types";
import { apiLink, useApi } from "../useApi";

export const useGetDeclaration = () => {
  const api = useApi<Declaration>();

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations`,
      }),
    [api],
  );
};

export const useGetReasons = () => {
  const api = useApi<Reason[]>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/reasons`,
      }),
    [api],
  );
};

export const usePostDeclarations = () => {
  const api = useApi<Declaration[]>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      reasonId,
      programOperatorId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      reasonId: string;
      programOperatorId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations`,
        data: { reason_id: reasonId, program_operator_id: programOperatorId },
      }),
    [api],
  );
};

export const useDocumentAccessToken = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    async ({
      manufacturerId,
      plantId,
      productId,
      documentId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      documentId: string;
    }) =>
      await api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/documents/${documentId}/access-token`,
      }),
    [api],
  );
};

export const useDownloadDocument = () => {
  const tokenResolver = useDocumentAccessToken();

  return useCallback(
    async ({
      manufacturerId,
      plantId,
      productId,
      documentId,
      documentName,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      documentId: string;
      documentName: string;
    }) => {
      const token = await tokenResolver({
        manufacturerId,
        plantId,
        productId,
        documentId,
      });

      const url = apiLink(
        `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/documents/${documentId}?token=${token}`,
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", documentName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
    [tokenResolver],
  );
};

export const useGetEditableEPD = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      reasonId,
      programOperatorId,
      token,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      reasonId: string;
      programOperatorId: string;
      token: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/epd/editable`,
        data: { reason_id: reasonId, program_operator_id: programOperatorId, token },
      }),
    [api],
  );
};

export const useGetLciaResults = () => {
  const api = useApi<LciaResults>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/lcia-results`,
      }),
    [api],
  );
};
