import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { ProductionProcess } from "../types";
import { useApi } from "../useApi";

export const useProductionProcesses = ({ plantId }: { plantId: string }) => {
  const api = useApi<ProductionProcess[]>();
  const { activeManufacturer } = useActiveManufacturer();

  const getProductionProcesses = useCallback(
    () =>
      api({
        url: `/v1/manufacturers/${activeManufacturer?.id}/plants/${plantId}/production-processes`,
      }),
    [api, activeManufacturer, plantId],
  );

  return useSuspenseQuery({
    queryKey: ["production-processes", activeManufacturer?.id, plantId],
    queryFn: getProductionProcesses,
  });
};

export const usePatchProductionProcess = () => {
  const queryClient = useQueryClient();
  const api = useApi<ProductionProcess>();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  const mutationFn = useCallback(
    (productionProcess: ProductionProcess) =>
      api({
        url: `/v1/manufacturers/${activeManufacturer?.id}/plants/${activePlant?.id}/production-processes/${productionProcess.id}`,
        method: "PUT",
        data: productionProcess,
      }),
    [activeManufacturer, api, activePlant],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["production-processes", activeManufacturer?.id, activePlant?.id],
      });
    },
  });
};
