import { ReactNode } from "react";
import { Dialog, Popover as PopoverPrimitive, type PopoverProps } from "react-aria-components";
import { OverlayArrow } from "./OverlayArrow";

export const Popover = ({
  children,
  ...props
}: Omit<PopoverProps, "children" | "className"> & {
  children: ReactNode;
}) => {
  return (
    <PopoverPrimitive {...props} className="p-4 bg-white border shadow-lg flex flex-col">
      <OverlayArrow />
      <Dialog className="outline-none">{children}</Dialog>
    </PopoverPrimitive>
  );
};
