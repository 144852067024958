import { ArrowBackOutlined, DeleteOutlined, FactoryOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeletePlant, usePatchPlant, usePostPlant } from "../api/endpoints/plants";
import { Button } from "../components/Button";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { Text16 } from "../components/Typography";
import {
  ManagePlantFields,
  ManagePlantFieldsT,
} from "../page-components/company-settings/ManagePlantFields";
import { useActiveManufacturer } from "../state/manufacturers";
import { usePlants, usePlantsLoader } from "../state/plants";
import { showSuccessToast } from "../util/toasts";

const usePlantForm = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant, setActivePlantId } = usePlants();
  const { refetch: refetchPlants } = usePlantsLoader({ manufacturerId: activeManufacturer?.id });

  const methods = useForm<ManagePlantFieldsT>({
    defaultValues: {
      name: isEdit ? activePlant?.name : undefined,
      city: isEdit ? activePlant?.city : undefined,
      country: isEdit ? activePlant?.country : undefined,
    },
  });

  const [loading, setLoading] = useState(false);

  const postPlant = usePostPlant();
  const patchPlant = usePatchPlant();

  const onSubmit: SubmitHandler<ManagePlantFieldsT> = async (data) => {
    if (!activeManufacturer) return;
    try {
      setLoading(true);
      if (isEdit && activePlant) {
        const result = await patchPlant({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          plant: {
            ...activePlant,
            ...data,
          },
        });
        setActivePlantId(result.id);
        await refetchPlants();
        navigate("/company-settings");
      } else if (!isEdit) {
        const result = await postPlant({
          manufacturerId: activeManufacturer.id,
          plant: {
            ...data,
            manufacturer_id: activeManufacturer.id,
            production_processes: [],
            electricity_mix: null,
            waste_treatment: {},
          },
        });
        setActivePlantId(result.id);
        await refetchPlants();
        navigate("/company-settings");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return {
    methods,
    activePlant,
    loading,
    onSubmit,
  };
};

export const AddPlant = () => {
  const { t } = useTranslation();
  const { methods, loading, onSubmit } = usePlantForm({ isEdit: false });

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          icon={<FactoryOutlined />}
          title={t("Add plant")}
          input={
            <Button type="submit" isDisabled={loading}>
              {loading && <CircularProgress size="24px" />}
              {t("Add plant")}
            </Button>
          }
        />
        <div className="flex-grow flex flex-col gap-8 py-8">
          <ManagePlantFields />
        </div>
      </PageContainer>
    </FormProvider>
  );
};

const usePlantDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const deletePlant = useDeletePlant();

  const { activeManufacturer } = useActiveManufacturer();
  const { activePlantId, activePlant, plants, setActivePlantId } = usePlants();

  const nextPlantWithoutDeleted = useMemo(
    () => plants.find((p) => p.id !== activePlantId),
    [plants, activePlantId],
  );

  const removePlant = async () => {
    if (!activeManufacturer || !activePlantId || !activePlant) return;

    setDeletionStaged(false);

    try {
      await deletePlant({ manufacturerId: activeManufacturer.id, plantId: activePlantId });
      if (nextPlantWithoutDeleted) {
        setActivePlantId(nextPlantWithoutDeleted?.id);
      }
      navigate("/company-settings");
      showSuccessToast(t("Successfully deleted plant '{{ name }}'", { name: activePlant.name }));
    } catch (e) {
      console.error(e);
    }
  };

  return {
    deletionStaged,
    removePlant,
    setDeletionStaged,
  };
};

export const EditPlant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { methods, activePlant, loading, onSubmit } = usePlantForm({ isEdit: true });

  const { deletionStaged, removePlant, setDeletionStaged } = usePlantDeletion();

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <TopBar
          icon={
            <Button onPress={() => navigate("/company-settings")} intent="secondary" square>
              <ArrowBackOutlined />
            </Button>
          }
          title={t("Edit plant")}
          input={
            <>
              <Button type="button" intent="transparent" onPress={() => setDeletionStaged(true)}>
                <DeleteOutlined />
                {t("Delete plant")}
              </Button>
              <Button type="submit" isDisabled={loading}>
                {loading && <CircularProgress size="24px" />}
                {t("Update plant")}
              </Button>
            </>
          }
        />
        <div className="flex-grow flex flex-col gap-8 py-8">
          <ManagePlantFields />
        </div>
      </PageContainer>
      {deletionStaged && (
        <ConfirmationModal
          title={t("Delete Plant")}
          content={
            <>
              <Text16 className="font-bold">{activePlant?.name}</Text16>
              <Text16>{t("Are you sure you want to delete this plant?")}</Text16>
            </>
          }
          onConfirm={removePlant}
          onCancel={() => setDeletionStaged(false)}
        />
      )}
    </FormProvider>
  );
};
