import tw from "tailwind-styled-components";
import { Label14 } from "./Typography";

const Bubble = tw("span")`block w-3 h-3 rounded-full shrink-0`;

const ReadyBubble = tw(Bubble)`bg-steelblue`;
const WaitingBubble = tw(Bubble)`bg-amber-500`;
const MissingBubble = tw(Bubble)`border border-dotted border-neutral-500`;
const DoneBubble = tw(Bubble)`border border-builtgreen bg-energyyellow`;

const SxLabel = tw(Label14)<{ $gray?: boolean; $ready?: boolean }>`
  ${({ $gray }) => $gray && "text-neutral-400"}
  ${({ $ready }) => $ready && "text-steelblue"}
`;

export const StatusIndicator = ({
  text,
  missing,
  ready,
  waiting,
  done,
}: {
  text: string;
  missing?: boolean;
  waiting?: boolean;
  ready?: boolean;
  done?: boolean;
}) => {
  return (
    <div className="flex items-center gap-3 shrink-0">
      {ready && <ReadyBubble />}
      {waiting && <WaitingBubble />}
      {missing && <MissingBubble />}
      {done && <DoneBubble />}
      <SxLabel $gray={missing} $ready={ready}>
        {text}
      </SxLabel>
    </div>
  );
};
