import { CheckCircle, Error, Info, WarningAmber } from "@mui/icons-material";
import { ReactNode } from "react";
import { toast } from "react-toastify";

export const showInfoToast = (content: ReactNode) => {
  toast(content, { autoClose: 6000, type: "info", icon: <Info className="text-steelblue" /> });
};

export const showSuccessToast = (content: ReactNode) => {
  toast(content, {
    autoClose: 6000,
    type: "success",
    icon: <CheckCircle className="text-green-500" />,
  });
};

export const showWarningToast = (content: ReactNode) => {
  toast(content, {
    autoClose: false,
    type: "warning",
    icon: <WarningAmber className="text-amber-500" />,
  });
};

export const showErrorToast = (content: ReactNode) => {
  toast(content, {
    autoClose: false,
    type: "error",
    icon: <Error className="text-red-500" />,
  });
};
