import { AddOutlined, WindowOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { Link } from "../../components/Link";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { useActiveProductCategories } from "../../state/manufacturers";
import { useProducts, useProductsLoader } from "../../state/products";
import { EmptyProductList } from "./EmptyProductList";
import { ProductCard } from "./ProductCard";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
`;

export const ManageProducts = () => {
  useProductsLoader();

  const { t } = useTranslation();

  const { products, initialized } = useProducts();
  const count = products.length;

  const { productCategoriesMap } = useActiveProductCategories();

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={t("Product Overview")}
        subtitle={
          <>
            {count} {t("products", { count })}
          </>
        }
        input={
          products.length > 0 ? (
            <Link href="/edit/product-details">
              <AddOutlined />
              {t("New Product")}
            </Link>
          ) : undefined
        }
      />
      <div className="flex-grow flex flex-col gap-8 py-8">
        {products.length > 0 && (
          <Content>
            {products.map((item) => (
              <ProductCard key={item.id} item={item} productCategoriesMap={productCategoriesMap} />
            ))}
          </Content>
        )}
        {initialized && products.length === 0 && <EmptyProductList />}
      </div>
    </PageContainer>
  );
};
