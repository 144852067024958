import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetLciaResults } from "../api/endpoints/declarations";
import { LciaResults, Product } from "../api/types";
import { showErrorToast } from "../util/toasts";

const initialState = {
  loading: false,
  setLoading: () => {},
  lciaResults: undefined,
  setLciaResults: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [lciaResults, setLciaResults] = useState<LciaResults>();
  const [loading, setLoading] = useState(false);

  return {
    loading,
    setLoading,
    lciaResults,
    setLciaResults,
  };
};

export const LCIAResultsProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useLCIAResults = () => {
  return useContext(Context);
};

export const useLoadLCIAResults = ({ product }: { product: Product }) => {
  const { setLoading, setLciaResults } = useLCIAResults();
  const { t } = useTranslation();
  const getLciaResults = useGetLciaResults();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const running = useRef(false);
  useEffect(() => {
    async function load() {
      if (running.current || !product) return;

      running.current = true;
      try {
        setLoading(true);
        const response = await getLciaResults({
          manufacturerId: product.manufacturer_id,
          plantId: product.plant_id,
          productId: product.id,
        });
        setLciaResults(response);
        setLoading(false);
      } catch (_) {
        showErrorToast(
          t("Failed to load data for life cycle impact assessment, please try again."),
        );
        setLoading(false);
      }
      running.current = false;
    }
    load();
  }, [t, getLciaResults, product, setLoading, setLciaResults]);
};
