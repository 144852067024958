import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteMaterial } from "../../../api/endpoints/materials";
import { SupplierMaterial } from "../../../api/types";
import { useActiveManufacturer } from "../../../state/manufacturers";
import { useActivePlant } from "../../../state/plants";
import { showSuccessToast } from "../../../util/toasts";

export const useMaterialsDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const deleteMaterial = useDeleteMaterial();

  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  const location = useLocation();
  const routeAfterDeletion = "/suppliers-and-materials";

  const removeMaterials = async (materialIds: SupplierMaterial["id"][]) => {
    if (!activeManufacturer || !activePlant || materialIds.length === 0) return;

    try {
      await Promise.all(
        materialIds.map((id) =>
          deleteMaterial({
            materialId: id,
            manufacturerId: activeManufacturer.id,
            plantId: activePlant.id,
          }),
        ),
      );

      setDeletionStaged(false);
      showSuccessToast(
        materialIds.length === 1
          ? t("Successfully deleted material")
          : t("Successfully deleted materials"),
      );

      if (location.pathname !== routeAfterDeletion) {
        navigate(routeAfterDeletion);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { deletionStaged, setDeletionStaged, removeMaterials };
};
