import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { usePatchProduct } from "../../api/endpoints/products";
import { Plant, Product, ProductCategory, ProductionProcess } from "../../api/types";
import { Heading3, Text16 } from "../../components/Typography";
import { RenderedFlow } from "../../page-components/product-detail/RenderedFlow";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useActiveManufacturer, useActiveProductCategories } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useLoadSelectedProduct, useSelectedProduct } from "../../state/products";
import { useSplash } from "../../state/splash";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { EditFlowNav } from "./EditFlowNav";

const useSubmitProductionProcess = ({
  activePlant,
  selectedProduct,
  selectedProcessId,
}: {
  activePlant?: Plant;
  selectedProduct: Product;
  selectedProcessId?: string;
}) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();
  const navigate = useNavigate();
  const loadSelectedProduct = useLoadSelectedProduct();
  const patchProduct = usePatchProduct();
  const [searchParams] = useSearchParams();
  const { setSplash } = useSplash();

  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    if (!activePlant || !activeManufacturer || !selectedProcessId) return;
    try {
      setLoading(true);
      await patchProduct({
        manufacturerId: activeManufacturer?.id,
        plantId: activePlant?.id,
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          production_process_id: selectedProcessId,
        },
      });
      await loadSelectedProduct();

      setLoading(false);
      if (CATEGORIES_WITHOUT_RECIPE.includes(selectedProduct.category.id)) {
        navigate({ pathname: `/products/${selectedProduct.id}` });
        setSplash({ message: t("New product created") });
      } else {
        navigate({ pathname: "/edit/product-recipe", search: searchParams.toString() });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [
    activePlant,
    activeManufacturer,
    loadSelectedProduct,
    navigate,
    patchProduct,
    searchParams,
    selectedProcessId,
    selectedProduct,
    setSplash,
    t,
  ]);

  return {
    loading,
    onSubmit,
  };
};

export const ProductProductionProcess = () => {
  const selectedProduct = useSelectedProduct();
  if (!selectedProduct) return null;

  return <ProductProductionProcessForm selectedProduct={selectedProduct} />;
};

export const ProductProductionProcessForm = ({ selectedProduct }: { selectedProduct: Product }) => {
  const { activePlant } = useActivePlant();

  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-specs"));

  const [selectedProcessId, setSelectedProcessId] = useState<string>();

  const { productCategoriesMap } = useActiveProductCategories();

  const { loading, onSubmit } = useSubmitProductionProcess({
    activePlant,
    selectedProduct,
    selectedProcessId,
  });

  const productionProcssesOfCategory =
    activePlant?.production_processes.filter(
      (pp) => pp.category_id === selectedProduct.category.id,
    ) ?? [];

  return (
    <div className="flex-grow flex flex-col gap-8 overflow-hidden mx-auto w-full max-w-6xl pb-20">
      <form
        className="flex-grow flex flex-col gap-5 overflow-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="flex-grow flex flex-col gap-5 overflow-scroll p-2">
          {productionProcssesOfCategory.map((process) => (
            <ProductionProcessItem
              key={process.id}
              item={process}
              category={productCategoriesMap[process.category_id]}
              isActive={process.id === selectedProcessId}
              isDisabled={
                !process.input_output_per_year?.some(
                  (io) => io.year === selectedProduct.year_under_review,
                )
              }
              onSelectItem={setSelectedProcessId}
            />
          ))}
        </div>
        <EditFlowNav
          onPrev={onPrev}
          nextSubmit
          nextLoading={loading}
          nextDisabled={!selectedProcessId}
        />
      </form>
    </div>
  );
};

const ItemContainer = tw("div")<{ $active?: boolean; $disabled?: boolean }>`
  bg-white
  rounded-lg
  p-8
  border-2
  hover:shadow
  hover:-translate-y-[1px]
  active:translate-y-0
  ${({ $active }) => $active && "border-energyyellow"}
  ${({ $disabled }) =>
    $disabled
      ? "opacity-70 cursor-not-allowed hover:shadow-none"
      : "cursor-pointer hover:border-energyyellow"}
`;

const ProductionProcessItem = ({
  item,
  category,
  isActive,
  isDisabled,
  onSelectItem,
}: {
  item: ProductionProcess;
  category?: ProductCategory;
  isActive: boolean;
  isDisabled: boolean;
  onSelectItem: (id: string) => void;
}) => {
  return (
    <ItemContainer
      $active={isActive}
      $disabled={isDisabled}
      onClick={isDisabled ? undefined : () => onSelectItem(item.id)}
    >
      <Heading3>{item.name}</Heading3>
      <Text16>{category?.name.en}</Text16>
      <RenderedFlow flow={item} />
    </ItemContainer>
  );
};
