import { FactoryOutlined } from "@mui/icons-material";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ElectricityMix } from "../page-components/plant-settings/ElectricityMix";
import { ProductionProcesses } from "../page-components/plant-settings/ProductionProcesses";
import { WasteTreatment } from "../page-components/plant-settings/WasteTreatment";
import { useInputsLoader } from "../state/inputs";
import { useManufacturers } from "../state/manufacturers";
import { usePlants, usePlantsLoader } from "../state/plants";

export enum ProductionForm {
  ElectricityMix = "electricityMix",
  PlantAttributes = "plantAttributes",
  WasteTreatment = "wasteTreatment",
}

export const Production = () => {
  const { activeManufacturer } = useManufacturers();
  const { refetch } = usePlantsLoader({ manufacturerId: activeManufacturer?.id });
  useInputsLoader();

  const { t } = useTranslation();
  const { activePlant } = usePlants();

  const [editing, setEditing] = useState<ProductionForm>();
  const editingState = { editing, setEditing };

  return (
    <PageContainer>
      <TopBar icon={<FactoryOutlined />} title={t("Production")} subtitle={activePlant?.name} />
      <div className="flex flex-col gap-8 py-8">
        {activePlant && (
          <>
            <ElectricityMix refetchPlants={refetch} {...editingState} />
            <WasteTreatment refetchPlants={refetch} {...editingState} />
            <Suspense>
              <ProductionProcesses activePlant={activePlant} />
            </Suspense>
          </>
        )}
      </div>
    </PageContainer>
  );
};
