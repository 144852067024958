import { AddOutlined, FactoryOutlined, UploadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBulkUploadPlants } from "../api/endpoints/plants";
import type { Plant } from "../api/types";
import { useMaxPlantsCount } from "../auth/auth";
import { Button } from "../components/Button";
import { ClickableCard } from "../components/ClickableCard";
import { Link } from "../components/Link";
import { Heading3, Label, Text16 } from "../components/Typography";
import { useActiveManufacturer } from "../state/manufacturers";
import { usePlants, usePlantsLoader } from "../state/plants";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3
`;

export const ManagePlants = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { refetch } = usePlantsLoader({ manufacturerId: activeManufacturer?.id });

  const { t } = useTranslation();

  const { plants, setActivePlantId } = usePlants();
  const maxPlants = useMaxPlantsCount();

  const bulkUploadPlants = useBulkUploadPlants();
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleUpload = (files: FileList | null) => {
    if (!activeManufacturer) {
      return;
    }

    setUploadLoading(true);
    if (files) {
      const file = files[0];

      bulkUploadPlants({
        manufacturerId: activeManufacturer.id,
        file,
      })
        .then((x) => {
          alert(
            `Plants uploaded successfully. Imported: ${x.imported}, Updated: ${x.updated}, Skipped: ${x.skipped}, Failed: ${x.failed} \n ${x.errors.join("\n")}`,
          );
          refetch();
        })
        .finally(() => {
          setUploadLoading(false);
        });
    }
  };

  return (
    <div className="flex-grow flex flex-col gap-8 py-8">
      <div className="grid grid-cols-2 items-center justify-between gap-3">
        <div>
          <Label>{t("Production Plants")}</Label>
          {maxPlants - plants.length < 2 && (
            <p>
              {t(
                "You have {{ plants }} plants out of {{ maxPlants }} allowed. To increase the limit please contact the support.",
                { plants: plants.length, maxPlants },
              )}
            </p>
          )}
        </div>
        <div className="flex gap-6 justify-end">
          <FileTrigger onSelect={handleUpload}>
            <Button intent="secondary" isDisabled={uploadLoading}>
              {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
              {t("Upload Plants")}
            </Button>
          </FileTrigger>
          <Link
            intent="secondary"
            isDisabled={plants.length >= maxPlants}
            href="/manage-plants/new"
          >
            <AddOutlined />
            {t("New Plant")}
          </Link>
        </div>
      </div>
      {plants.length > 0 && (
        <Content>
          {plants.map((item) => (
            <PlantCard key={item.id} item={item} setActivePlantId={setActivePlantId} />
          ))}
        </Content>
      )}
    </div>
  );
};

const PlantCard = ({
  item,
  setActivePlantId,
}: {
  item: Plant;
  setActivePlantId: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return (
    <ClickableCard
      className="group relative"
      onClick={() => {
        setActivePlantId(item.id);
        navigate("/manage-plants/edit");
      }}
    >
      <div className="space-y-2">
        <FactoryOutlined className="text-neutral-500" />
        <div className="spcae-y-1">
          <Heading3>{item.name}</Heading3>
          <Text16 className="text-neutral-500">{item.city}</Text16>
        </div>
      </div>
    </ClickableCard>
  );
};
