import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { ReactNode, useMemo } from "react";
import { useNavigation } from "../state/navigation";
import { IconContainer } from "./IconContainer";
import { Text16 } from "./Typography";

const containerStyles = cva(
  ["flex", "items-center", "justify-between", "gap-3", "px-6", "h-[var(--topbar-height)]"],
  {
    variants: {
      variant: {
        default: "",
        concrete: "bg-concrete bg-texture",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export const TopBar = ({
  icon,
  onIconClick,
  title,
  subtitle,
  extra,
  centerSection,
  input,
  variant,
}: {
  icon?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  extra?: ReactNode;
  centerSection?: ReactNode;
  input?: ReactNode;
  onIconClick?: () => void;
} & VariantProps<typeof containerStyles>) => {
  const { collapsed, showNavigation } = useNavigation();

  const style = useMemo(() => {
    if (!showNavigation) return { left: "0" };

    return collapsed ? { left: "var(--navbar-width-collapsed)" } : { left: "var(--navbar-width)" };
  }, [collapsed, showNavigation]);

  return (
    <div className="fixed bg-white top-0 right-0 z-20" style={style}>
      <div className={containerStyles({ variant })}>
        <div className="flex items-center gap-5">
          {icon && (
            <IconContainer role={onIconClick ? "button" : undefined} onClick={onIconClick} $pale>
              {icon}
            </IconContainer>
          )}
          <div>
            <Text16 className="font-bold">{title}</Text16>
            <Text16>{subtitle}</Text16>
          </div>
          {extra && <div className="ml-3">{extra}</div>}
        </div>
        {centerSection}
        {input && <div className="flex items-center gap-6 justify-end">{input}</div>}
      </div>
      <hr className="border-t border-neutral-300 m-0" />
    </div>
  );
};
