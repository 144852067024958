import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField } from "../../components/TextField";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { TextAreaFieldConnected } from "../../form-components/TextAreaFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useProductCategories } from "../../state/manufacturers";
import { usePlants } from "../../state/plants";
import { ProductDetailsForm } from "./types";

export const ProductDetailsFields = ({ existingProduct }: { existingProduct: boolean }) => {
  const { t } = useTranslation();
  const { activePlant } = usePlants();

  const { watch, setValue } = useFormContext<ProductDetailsForm>();

  const unit = watch("unit");
  const periodUnderReview = watch("year_under_review");
  const elementaryId = watch("elementary_id");
  const category = watch("categoryName");

  const { elementaries, elementariesMap } = useElementaries();
  const produceCategories = useProductCategories();

  if (existingProduct && elementaryId && !category) {
    setValue("categoryName", elementaries.find((x) => x.id === elementaryId)!.category);
  }

  const plantProducesElementaries = useMemo(() => {
    if (!activePlant) return [];

    const plantProducesCategories = activePlant.production_processes.map(
      (productionProcess) => productionProcess.category_id,
    );
    return elementaries.filter(
      (elementary) =>
        elementary.active && plantProducesCategories.includes(elementary.product_category_id),
    );
  }, [elementaries, activePlant]);

  const categoryToElementaryMap = useMemo(() => {
    const map: Record<string, string[]> = {};
    elementaries.forEach((elementary) => {
      if (elementary.active && elementary.product_category_id) {
        if (!map[elementary.category]) {
          map[elementary.category] = [];
        }
        map[elementary.category].push(elementary.id);
      }
    });
    return map;
  }, [elementaries]);

  const categoryOptions = useMemo(() => {
    return Object.keys(categoryToElementaryMap)
      .map((category) => ({
        id: category,
        label: category,
        isDisabled: !plantProducesElementaries.some((elementary) =>
          categoryToElementaryMap[category].includes(elementary.id),
        ),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [categoryToElementaryMap, plantProducesElementaries]);

  const materialOptions = useMemo(() => {
    return elementaries
      .filter((elementary) => elementary.active && elementary.category === category)
      .map((elementary) => ({
        id: elementary.id,
        label: elementary.name_en,
        isDisabled: !plantProducesElementaries.some((x) => x.id === elementary.id),
      }))
      .sort((a, b) => {
        if (a.isDisabled === b.isDisabled) {
          return a.label.localeCompare(b.label);
        } else {
          return a.isDisabled ? 1 : -1;
        }
      });
  }, [elementaries, category, plantProducesElementaries]);

  const unitOptions = useMemo(() => {
    const productCategoryId = elementariesMap[elementaryId]?.product_category_id;
    if (!productCategoryId) return [];
    const allowUnits =
      produceCategories.find((x) => x.id === productCategoryId)?.allowed_units ?? [];
    return allowUnits.map((unit) => ({ id: unit, label: unit.toLowerCase() }));
  }, [elementaryId, elementariesMap, produceCategories]);

  const yearOptions = useMemo(() => {
    if (!activePlant) return [];
    const selectedCategoryId = elementariesMap[elementaryId]?.product_category_id;
    const productionProcesses =
      activePlant?.production_processes.filter((pp) => pp.category_id === selectedCategoryId) ?? [];
    const years = productionProcesses.flatMap(
      (pp) => pp.input_output_per_year?.map((io) => io.year) ?? [],
    );
    const uniqueYears = Array.from(new Set(years));
    return uniqueYears.map((year) => ({ id: year, label: year.toString() }));
  }, [activePlant, elementariesMap, elementaryId]);

  return (
    <div className="overflow-x-hidden overflow-y-auto py-2 grid grid-cols-[1fr_1fr] gap-8 max-w-6xl w-full mx-auto">
      <TextFieldConnected name="name" isRequired placeholder="Name" label={t("Product Name")} />
      <TextField label={t("Production Plant")} isDisabled value={activePlant?.name} />

      <ComboBoxFieldConnected
        name="categoryName"
        options={categoryOptions}
        isDisabled={existingProduct}
        label={t("Category")}
        isRequired
      />
      <ComboBoxFieldConnected
        name="elementary_id"
        label={t("Material")}
        isRequired
        options={materialOptions}
        isDisabled={existingProduct || !category}
      />
      <TextAreaFieldConnected name="description" label={t("Product Description")} />
      <TextAreaFieldConnected name="use_case" label={t("Application / Use case")} />
      <div className="flex flex-row gap-3">
        <ComboBoxFieldConnected
          name="unit"
          label={t("Unit")}
          isRequired
          options={unitOptions}
          isDisabled={existingProduct || !category}
        />
        <TextFieldConnected
          name="referenceUsePeriod"
          label={t("Reference Use Period")}
          isDisabled
        />
      </div>
      <div className="grid grid-cols-[1fr_2.5fr] gap-3">
        <ComboBoxFieldConnected
          name="year_under_review"
          isRequired
          options={yearOptions}
          isDisabled={existingProduct || !category}
          label={t("Period under review")}
          placeholder="YYYY"
        />
        <NumberFieldConnected
          name="production_output"
          minValue={0}
          label={
            <>
              {t("Production output for period under review")}{" "}
              {periodUnderReview ? <span className="font-bold">{periodUnderReview}</span> : "____"}
            </>
          }
          isRequired
          inputProps={
            unit
              ? {
                  addonRight: unit.toLowerCase(),
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};
