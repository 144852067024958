import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { v4 as uuidv4 } from "uuid";

import { Link } from "react-router-dom";
import emidat from "../../assets/logo.png";
import ec3 from "../../assets/publication-imgs/ec3.jpg";
import ecoplatform from "../../assets/publication-imgs/ecoplatform.png";
import epdonline from "../../assets/publication-imgs/epd-online.png";
import { ClickableCard } from "../../components/ClickableCard";
import { StatusIndicator } from "../../components/StatusIndicator";
import { Text14 } from "../../components/Typography";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";

interface PublicationOption {
  id: string;
  description: string;
  imgSrc: string;
  status: "approved" | "pending" | "not-started";
}

const usePublicationOptions = (): PublicationOption[] => {
  const { t } = useTranslation();

  return [
    {
      id: uuidv4(),
      description: t(
        "Publish your EPD in the Emidat Database. Emidat integrates your environmental data seamlessly into the workflows of thousands of construction professionals.",
      ),
      status: "approved",
      imgSrc: emidat,
    },
    {
      id: uuidv4(),
      description: t(
        "Publish your EPD in Ecoplatform. Leading manufactureres and suppliers of construction products publish their EPDs in Ecoplatform.",
      ),
      status: "pending",
      imgSrc: ecoplatform,
    },
    {
      id: uuidv4(),
      description: t(
        "Publish your EPD with The International EPD System. A leading EPD system that makes your EPD available worldwide.",
      ),
      status: "pending",
      imgSrc: epdonline,
    },
    {
      id: uuidv4(),
      description: t(
        "Publish your EPD with Building Transparency. Their EC3 platform is a free, open-access tool that helps you find and compare the carbon emissions embodied within construction materials.",
      ),
      status: "not-started",
      imgSrc: ec3,
    },
  ];
};

const Content = tw("div")`
  grid
  grid-cols-2
  gap-5
`;

const Img = tw("img")`h-20 object-contain`;

export const Publication = () => {
  const options = usePublicationOptions();
  const getLinkWithParams = useGetLinkWithParams();

  return (
    <Content>
      {options.map((option) => (
        <Link to={getLinkWithParams("")} key={option.id}>
          <ClickableCard className="flex flex-col gap-8 px-8 py-6" key={option.id}>
            <StatusIndicator
              done={option.status === "approved"}
              waiting={option.status === "pending"}
              missing={option.status === "not-started"}
              text={option.status}
            />
            <Img src={option.imgSrc} />
            <Text14>{option.description}</Text14>
          </ClickableCard>
        </Link>
      ))}
    </Content>
  );
};
