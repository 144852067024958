import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EndOfScale, Product } from "../../api/types";
import { useLCIAResults } from "../../state/lciaResults";
import { BreakdownPerStage } from "./BreakdownPerStage";
import { EnvironmentalPerformanceIndicators } from "./EnvironmentalPerformanceIndicators";
import { ProductImage } from "./ProductImage";
import { ProductStats } from "./ProductStats";
import { ProductSummary } from "./ProductSummary";

export const ProductDetailPagePassport = ({
  product,
  endsOfScale,
}: {
  product: Product;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();
  const { loading, lciaResults } = useLCIAResults();

  return (
    <div className="flex flex-col pb-8 gap-8">
      <div className="flex flex-col gap-8">
        <div className="-mx-6 px-6 pt-8 pb-4 bg-concrete bg-texture flex gap-6">
          <ProductImage product={product} />
          <div className="flex flex-col">
            <ProductSummary product={product} />
            {!loading && lciaResults && (
              <>
                <hr className="border-t mt-6 mb-4 border-neutral-300" />
                <ProductStats lciaResults={lciaResults} baseUnit={product.unit} />
              </>
            )}
          </div>
        </div>
        <div>
          {loading && (
            <div className="flex flex-col justify-center items-center h-96 gap-3">
              <CircularProgress size="24px" />
              {t("Loading impact assessment data...")}
            </div>
          )}
          {!loading && lciaResults && (
            <div className="flex flex-col gap-8">
              <EnvironmentalPerformanceIndicators
                impactSummary={lciaResults.impact_summary}
                endsOfScale={endsOfScale}
              />
              <hr className="border-t border-neutral-300 mb-12" />
              <BreakdownPerStage impactSummary={lciaResults.impact_summary} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
