import { InfoOutlined } from "@mui/icons-material";
import { ComponentProps } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { Tooltip, TooltipTrigger } from "./Tooltip";

export const InfoTooltip = (props: ComponentProps<typeof Tooltip>) => {
  return (
    <TooltipTrigger>
      <ButtonPrimitive>
        <InfoOutlined fontSize="small" />
      </ButtonPrimitive>
      <Tooltip {...props} />
    </TooltipTrigger>
  );
};
