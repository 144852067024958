import { cva } from "class-variance-authority";
import type { ReactNode } from "react";
import type { TooltipProps, TooltipTriggerComponentProps } from "react-aria-components";
import {
  OverlayArrow,
  Tooltip as TooltipPrimitive,
  TooltipTrigger as TooltipTriggerPrimitive,
} from "react-aria-components";

const tooltipStyles = cva([
  "max-w-sm",
  "rounded-lg",
  "bg-neutral-100",
  "text-builtgreen",
  "py-2 px-3",
  "text-base",
  "shadow-md",
  "outline-none",
]);

export const Tooltip = ({
  title,
  children,
  ...props
}: Omit<TooltipProps, "className" | "styles" | "children"> & {
  title?: string;
  children?: ReactNode;
}) => (
  <TooltipPrimitive {...props} className={tooltipStyles()} offset={16}>
    <OverlayArrow
      className={({ placement }) => {
        if (placement === "top") return "[&>svg]:rotate-0";
        if (placement === "left") return "[&>svg]:-rotate-90";
        if (placement === "bottom") return "[&>svg]:rotate-180";
        return "[&>svg]:tw-rotate-90";
      }}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" className="fill-neutral-100">
        <path d="M0 0 L8 8 L16 0" stroke="#e5e5e5" strokeWidth="1" />
      </svg>
    </OverlayArrow>
    {!!title && <div className="mb-1 font-medium">{title}</div>}
    {children}
  </TooltipPrimitive>
);

export const TooltipTrigger = ({ children, ...props }: TooltipTriggerComponentProps) => (
  <TooltipTriggerPrimitive {...props} delay={0} closeDelay={0}>
    {children}
  </TooltipTriggerPrimitive>
);
