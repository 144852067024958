import { Article, LaunchOutlined } from "@mui/icons-material";
import { CircularProgress, FormControlLabel, Radio } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { usePostSearchResults } from "../../../api/endpoints/search";
import { EPDSearchResult, SecondaryDataset, SupplierMaterial } from "../../../api/types";
import { Alert } from "../../../components/Alert";
import { IconContainer } from "../../../components/IconContainer";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Link } from "../../../components/Link";
import { Heading3, Label, Text14 } from "../../../components/Typography";
import { useElementaries } from "../../../state/elementaries";
import { FormFields } from "./types";

type SecondaryDatasetWithOptions = Partial<SecondaryDataset>;

export const LinkEnvironmentalData = ({
  existingMaterial,
}: {
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { elementariesMap } = useElementaries();
  const elementaryId = methods.watch("elementaryId");
  const epdId = methods.watch("epdId");

  const materialMatching = elementariesMap[elementaryId]?.matching;

  const productName = methods.watch("productName");

  const supplierCompanyName = methods.watch("supplierCompanyName");

  const supplierName = existingMaterial
    ? existingMaterial.supplier_plant?.supplier_company.name
    : supplierCompanyName;

  const postSearchResults = usePostSearchResults();

  const [loading, setLoading] = useState(false);
  const [primaryData, setPrimaryData] = useState<EPDSearchResult[]>([]);

  useDebounce(
    async () => {
      if (!productName || !supplierName) return;

      setLoading(true);
      const result = await postSearchResults({
        searchTerm: [productName, supplierName].join(" "),
        language: "en",
      });
      setLoading(false);

      setPrimaryData(result);

      if (
        !existingMaterial ||
        (existingMaterial.name !== productName &&
          existingMaterial.supplier_plant?.supplier_company.name !== supplierName)
      ) {
        methods.setValue("epdId", null);
      }
    },
    existingMaterial ? 0 : 500,
    [productName, supplierName, existingMaterial],
  );

  const linkedSecondaryData: SecondaryDatasetWithOptions[] = useMemo(() => {
    if (existingMaterial && existingMaterial.secondary_data) {
      return existingMaterial.secondary_data;
    } else if (materialMatching) {
      return materialMatching.map((match) => ({
        activity_name: match[0],
      }));
    } else return [];
  }, [existingMaterial, materialMatching]);

  return (
    <>
      <Heading3>{t("Link Environmental Data")}</Heading3>
      <hr />
      {loading && (
        <Label className="flex items-center gap-3">
          <CircularProgress size="24px" /> {t("Searching data ...")}
        </Label>
      )}
      {primaryData.length > 0 && <PrimaryData primaryData={primaryData} />}
      {linkedSecondaryData.length > 0 && (
        <SecondaryData data={linkedSecondaryData} hasOtherOptions={primaryData.length > 0} />
      )}
      {!epdId && linkedSecondaryData.length > 0 && (
        <Alert intent="info">
          <Text14>
            {primaryData.length === 0 && t("No EPD data found.")}{" "}
            {t("Using conservative data from secondary databases.")}
            <br />
            {t(
              "Request EPD from your supplier to improve accuracy of environmental impact calculation.",
            )}
          </Text14>
        </Alert>
      )}
    </>
  );
};

const PrimaryData = ({ primaryData }: { primaryData: EPDSearchResult[] }) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex items-center gap-2">
        {t("EPD data")}
        <InfoTooltip>
          {t(`EPD data is the life cycle inventory (LCI) data provided by published EPDs.`)}
        </InfoTooltip>
      </Label>
      {primaryData.map((item) => (
        <label
          htmlFor={`field-${item.id}`}
          key={item.id}
          className="flex gap-4 items-center max-w-full"
        >
          <IconContainer $active $blue className="shrink-0">
            <Article />
          </IconContainer>
          <div className="flex flex-col flex-1">
            <Link intent="link" size="small" href={item.item_source_url} target="_blank">
              {item.name_en}
              {!!item.item_source_url && (
                <LaunchOutlined className="text-neutral-500" fontSize="small" />
              )}
            </Link>
            <p className="text-sm line-clamp-1 shrink-0">
              {item.manufacturer}, {item.reference_year}
            </p>
          </div>
          <Controller
            name="epdId"
            control={methods.control}
            render={({ field: { value, ...field } }) => (
              <FormControlLabel
                id={`field-${item.id}`}
                value={item.id}
                className="text-blue-600"
                labelPlacement="start"
                disabled={!item.can_be_used}
                control={<Radio {...field} checked={value === item.id} defaultChecked={false} />}
                label={item.can_be_used ? t("Select") : t("Can’t select")}
              />
            )}
          />
        </label>
      ))}
    </div>
  );
};

const SecondaryData = ({
  data,
  hasOtherOptions,
}: {
  data: SecondaryDatasetWithOptions[];
  hasOtherOptions?: boolean;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  return (
    <div>
      <Label className="flex items-center gap-2">
        {t("Secondary Data")}
        <InfoTooltip>
          {t(
            `Secondary data is the life cycle inventory (LCI) data provided by existing databases.`,
          )}
        </InfoTooltip>
      </Label>
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex gap-4 items-center max-w-full">
          <IconContainer $pale className="shrink-0">
            <Article />
          </IconContainer>
          <div className="flex flex-col flex-1">
            {data.map((item) => (
              <Fragment key={item.activity_name}>
                <Link intent="link" size="small" href={item.source_url} target="_blank">
                  {item.activity_name}
                  {!!item.source_url && (
                    <LaunchOutlined className="text-neutral-500" fontSize="small" />
                  )}
                </Link>
                <p className="text-sm">
                  {t("Data source")}: Ecoinvent 3.10 {item.geography && `(${item.geography})`}
                </p>
              </Fragment>
            ))}
          </div>
          {hasOtherOptions && (
            <Controller
              name="epdId"
              control={methods.control}
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  id="field-secondary"
                  className="text-blue-600"
                  labelPlacement="start"
                  control={<Radio {...field} checked={value === null || value === ""} />}
                  label={t("Select")}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
