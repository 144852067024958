import { v4 as uuidv4 } from "uuid";
import { Heading3 } from "../../components/Typography";

import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import epdDenmark from "../../assets/verification-imgs/epd-denmark.png";
import epdIreland from "../../assets/verification-imgs/epd-ireland.png";
import epdItaly from "../../assets/verification-imgs/epd-italy.png";
import epdNorge from "../../assets/verification-imgs/epd-norge.jpg";
import ibu from "../../assets/verification-imgs/ibu.png";
import inies from "../../assets/verification-imgs/inies.png";
import pep from "../../assets/verification-imgs/pep.png";
import { ClickableCard } from "../../components/ClickableCard";
import { StatusIndicator } from "../../components/StatusIndicator";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";

interface VerificationOption {
  id: string;
  title: string;
  imgSrc: string;
  status: "approved" | "pending" | "not-started";
}

const usePublicationOptions = (): VerificationOption[] => {
  // Probably necessary as soon as there are translatable descriptions, etc
  // const { t } = useTranslation();

  return [
    {
      id: uuidv4(),
      title: "IBU – Institut Bauen und Umwelt e.V.",
      status: "pending",
      imgSrc: ibu,
    },
    {
      id: uuidv4(),
      title: "The Norwegian EPD Foundation",
      status: "approved",
      imgSrc: epdNorge,
    },
    {
      id: uuidv4(),
      title: "Programme INIES",
      status: "not-started",
      imgSrc: inies,
    },
    {
      id: uuidv4(),
      title: "PEP ecopassport",
      status: "not-started",
      imgSrc: pep,
    },
    {
      id: uuidv4(),
      title: "EPD Denmark",
      status: "not-started",
      imgSrc: epdDenmark,
    },
    {
      id: uuidv4(),
      title: "EPD Italy",
      status: "not-started",
      imgSrc: epdItaly,
    },
    {
      id: uuidv4(),
      title: "EPD Ireland",
      status: "not-started",
      imgSrc: epdIreland,
    },
  ];
};

const Content = tw("div")`
  grid
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
  gap-3
`;

const Img = tw("img")`w-full h-full object-contain`;

export const Verification = () => {
  const options = usePublicationOptions();
  const getLinkWithParams = useGetLinkWithParams();

  return (
    <Content>
      {options.map((option) => (
        <Link to={getLinkWithParams("")} key={option.id}>
          <ClickableCard className="flex flex-col justify-between gap-8 px-8 py-6" key={option.id}>
            <StatusIndicator
              text={option.status}
              done={option.status === "approved"}
              waiting={option.status === "pending"}
              missing={option.status === "not-started"}
            />
            <div className="flex items-center justify-center overflow-hidden max-w-20 max-h-20">
              <Img src={option.imgSrc} />
            </div>
            <Heading3 className="text-center">{option.title}</Heading3>
          </ClickableCard>
        </Link>
      ))}
    </Content>
  );
};
