import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { LciaResults, SourceType } from "../../api/types";
import { ToggleButton } from "../../components/Button";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Label12 } from "../../components/Typography";
import { BreakdownPerMaterialChart, Totals } from "./BreakdownPerMaterialChart";

const FiltersDivider = tw("div")`
  w-[1px]
  h-[22px]
  bg-gray-200
`;

export const BreakdownPerMaterial = ({ lcia }: { lcia: LciaResults }) => {
  const { t } = useTranslation();

  const [category, setCategory] = useState<null | SourceType>(null);

  const filteredData = useMemo(() => {
    if (!category) {
      return lcia.impacts_per_source;
    }

    return lcia.impacts_per_source.filter((x) => x.type === category);
  }, [category, lcia]);

  const impactsA13perIndicator = Object.fromEntries(
    lcia.impact_summary.a1_a3.map((i) => [i.indicator_name, i.value]),
  ) as Totals;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Label12 className="flex items-center gap-2">
          {t("Detailed breakdown of impact sources")}
          <InfoTooltip>
            {t("Get detailed insights of the sources that cause the most impact per category.")}
          </InfoTooltip>
        </Label12>
        <div className="flex items-center gap-2">
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory(null)}
            active={!category}
          >
            {t("ALL")}
          </ToggleButton>
          <FiltersDivider />
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory("raw_materials")}
            active={category === "raw_materials"}
          >
            {t("RAW MATERIALS")}
          </ToggleButton>
          <FiltersDivider />
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory("energy_and_fuels")}
            active={category === "energy_and_fuels"}
          >
            {t("PRODUCTION ENERGY")}
          </ToggleButton>
          <FiltersDivider />
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory("ancillary_materials")}
            active={category === "ancillary_materials"}
          >
            {t("ANCILLARY MATERIALS")}
          </ToggleButton>
          <FiltersDivider />
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory("waste")}
            active={category === "waste"}
          >
            {t("WASTE")}
          </ToggleButton>
          <FiltersDivider />
          <ToggleButton
            size="tiny"
            intent="transparent"
            onPress={() => setCategory("packaging")}
            active={category === "packaging"}
          >
            {t("PACKAGING")}
          </ToggleButton>
          {lcia.impacts_per_source.some((i) => i.type === "other") && (
            <>
              <FiltersDivider />
              <ToggleButton
                size="tiny"
                intent="transparent"
                onPress={() => setCategory("other")}
                active={category === "other"}
              >
                {t("OTHER")}
              </ToggleButton>
            </>
          )}
        </div>
      </div>
      <div className="w-full h-[200px]">
        {filteredData && filteredData.length > 0 ? (
          <BreakdownPerMaterialChart
            impactsPerSource={filteredData}
            totals={impactsA13perIndicator}
          />
        ) : (
          <div className="w-full text-center mt-12 text-gray-500">{t("No sources")}</div>
        )}
      </div>
    </div>
  );
};
