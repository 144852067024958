export const formatNumber = (value: number): string => {
  if (value < 0) {
    return "< 0";
  } else if (value === 0) {
    return "0";
  } else if (value < 0.01) {
    return "< 0.01";
  } else {
    return `${value.toFixed(2)}`;
  }
};

export const formatPercent = (value: number): string => {
  return `${(value * 100).toFixed(2)}%`;
};

export const formatPrecision = (value: number, precision: number): string => {
  if (Math.abs(value) < 1 / 10 ** precision) {
    return value.toExponential(precision);
  } else {
    return value.toPrecision(precision);
  }
};
