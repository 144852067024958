import { useCallback, useEffect, useState } from "react";
import { useGetDeclaration } from "../api/endpoints/declarations";
import { Declaration } from "../api/types";

export const useLoadDeclaration = ({
  productId,
  plantId,
  manufacturerId,
}: {
  productId: string;
  plantId: string;
  manufacturerId: string;
}) => {
  const [data, setData] = useState<Declaration | null>(null);

  const getDeclarations = useGetDeclaration();

  const load = useCallback(async () => {
    try {
      const response = await getDeclarations({
        manufacturerId,
        plantId,
        productId,
      });
      setData(response);
    } catch (e) {
      console.error(e);
    }
  }, [getDeclarations, manufacturerId, productId, plantId]);

  useEffect(() => {
    load();
  }, [load]);

  return { data, refetch: load };
};
