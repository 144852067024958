import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { Impact, ImpactSummary } from "../../api/types";
import { Text14 } from "../../components/Typography";
import { coreIndicators, filterImpact, mergeImpact } from "../../lca/impact";
import { formatPrecision } from "../../util/format";
import { useImpactColors } from "./useImpactColors";

export const LifeCycleStagesChart = ({ impactSummary }: { impactSummary: ImpactSummary }) => {
  const colors = useImpactColors();
  const { t } = useTranslation();

  //simplified version of the original code

  const total = mergeImpact(...Object.values(impactSummary));
  const totalMap: Record<string, number> = total.reduce(
    (acc, impact) => ({ ...acc, [impact.indicator_name]: impact.value }),
    {},
  );

  const stageFactory = (state: string, impact: Impact[]) =>
    impact.reduce(
      (acc, impact) => ({
        ...acc,
        [impact.indicator_name]: impact.value / Math.abs(totalMap[impact.indicator_name]),
        [impact.indicator_name + "_unit"]: impact.unit,
        stage: state,
      }),
      {
        stage: state,
      },
    );

  const productionStageImpact = filterImpact(impactSummary.a1_a3, coreIndicators);
  const productionStage = stageFactory(t("Production Stage"), productionStageImpact);

  const stages = [productionStage];

  if (impactSummary.a4 !== null || impactSummary.a5 !== null) {
    const constructionStageImpact = filterImpact(
      mergeImpact(impactSummary.a4, impactSummary.a5),
      coreIndicators,
    );
    const constructionStage = stageFactory(t("Construction Stage"), constructionStageImpact);
    stages.push(constructionStage);
  }

  if (impactSummary.b1 !== null) {
    const useStageImpact = filterImpact(mergeImpact(impactSummary.b1), coreIndicators);
    const useStage = stageFactory(t("Use Stage"), useStageImpact);
    stages.push(useStage);
  }

  if (
    impactSummary.c1 !== null ||
    impactSummary.c2 !== null ||
    impactSummary.c3 !== null ||
    impactSummary.c4 !== null
  ) {
    const eolStageImpact = filterImpact(
      mergeImpact(impactSummary.c1, impactSummary.c2, impactSummary.c3, impactSummary.c4),
      coreIndicators,
    );
    const eolStage = stageFactory(t("End of Life Stage"), eolStageImpact);
    stages.push(eolStage);
  }

  if (impactSummary.d !== null) {
    const beyondStageImpact = filterImpact(impactSummary.d, coreIndicators);
    const beyondStage = stageFactory(t("Beyond the Life Cycle"), beyondStageImpact);
    stages.push(beyondStage);
  }

  return (
    <ResponsiveBar
      data={stages}
      keys={Array.from(coreIndicators)}
      indexBy="stage"
      margin={{ top: 5, bottom: 25 }}
      padding={0.2}
      innerPadding={4}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={(x) => {
        return colors[x.id];
      }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickValues: [],
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      gridYValues={[-1, -0.5, 0, 0.5, 1]}
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      enableLabel={false}
      tooltip={({ value, id, data }) => {
        return (
          <div className="bg-white p-2 rounded-md shadow-md">
            <Text14>
              {id}{" "}
              <strong>
                {formatPrecision(value * Math.abs(totalMap[id]), 4)}{" "}
                {(data as Record<string, string>)[`${id}_unit`]}
              </strong>
            </Text14>
          </div>
        );
      }}
    />
  );
};
