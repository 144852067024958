import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Outlet } from "react-router-dom";

import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { SplashSuccess } from "../components/SplashSuccess";
import { Banner } from "../page-components/Banner";
import { VersionInfo } from "../page-components/VersionInfo";
import { useElementariesLoader } from "../state/elementaries";
import { useManufacturers, useManufacturersLoader } from "../state/manufacturers";
import { usePlantsLoader } from "../state/plants";
import { useSelectedProductLoader } from "../state/products";

export const Index = () => {
  const { activeManufacturer } = useManufacturers();

  useManufacturersLoader();
  useSelectedProductLoader();
  useElementariesLoader();

  usePlantsLoader({ manufacturerId: activeManufacturer?.id });

  return (
    <>
      <Banner />
      <SplashSuccess />
      <div className="w-full flex-grow grid grid-cols-[auto,1fr] overflow-hidden">
        <VersionInfo />
        <Navbar />
        <div className="flex-grow px-6 overflow-y-auto bg-white flex flex-col">
          <Outlet />
          <Footer />
        </div>
      </div>
      <ToastContainer position="bottom-right" draggable pauseOnHover closeButton hideProgressBar />
    </>
  );
};
