import { UploadFileOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useImage, usePatchManufacturer, useUploadImage } from "../../api/endpoints/manufacturers";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Label14, Text14 } from "../../components/Typography";
import { useActiveManufacturer, useManufacturersLoader } from "../../state/manufacturers";
import { showErrorToast } from "../../util/toasts";

export const ManageCompanyLogo = () => {
  const { t } = useTranslation();

  const uploadImage = useUploadImage();
  const updateManufacturer = usePatchManufacturer();

  const { activeManufacturer } = useActiveManufacturer();
  const { refetch } = useManufacturersLoader();

  const handleFileDrop = async (files: FileList | null) => {
    const file = files?.[0];
    if (!file || !activeManufacturer) return;

    try {
      const key = await uploadImage({
        manufacturerId: activeManufacturer?.id,
        file,
      });
      await updateManufacturer({
        manufacturerId: activeManufacturer.id,
        manufacturer: { ...activeManufacturer, image_url: key },
      });
      refetch();
    } catch (_) {
      showErrorToast(t("Couldn't upload company logo."));
    }
  };

  return (
    <Card title={t("Company logo")}>
      <div className="text-right w-full">
        <Text14 className="text-neutral-500">{t("EPD document preview")}</Text14>
      </div>
      <div className="grid grid-cols-2 gap-8 items-start">
        <div className="space-y-4 mt-5 ml-8">
          <Text14 className="text-neutral-500">
            {t("Pick a logo to display on the header of your generated EPDs.")}
            <br />
            {/*
            {t("Ideal size is 256px - 660px (PNG / SVG / WEBP)")}
            Displaying SVG in the UI did not work out of the box.
            */}
            {t("Ideal size is 256px - 660px (PNG / JPEG / WEBP)")}
          </Text14>
          <FileTrigger onSelect={handleFileDrop}>
            <Button intent="secondary">
              <UploadFileOutlined />
              {activeManufacturer?.image_url ? t("Update company logo") : t("Upload company logo")}
            </Button>
          </FileTrigger>
        </div>
        <EPDPreviewSkeletton />
      </div>
    </Card>
  );
};

const EPDPreviewSkeletton = () => {
  const { t } = useTranslation();

  const { activeManufacturer } = useActiveManufacturer();
  const getImageAccessLink = useImage();

  const [imageAccessLink, setImageAccessLink] = useState<string | null>(null);

  useEffect(() => {
    const getImageSrc = async () => {
      if (!activeManufacturer?.image_url) return;
      try {
        const img = await getImageAccessLink({
          manufacturerId: activeManufacturer.id,
          path: activeManufacturer.image_url,
        });
        setImageAccessLink(img);
      } catch (error) {
        console.error("Error generating image url:", error);
      }
    };

    getImageSrc();
  }, [activeManufacturer, getImageAccessLink]);

  return (
    <div className="border-t border-x translate-y-5 px-9 py-4">
      <div className="space-y-3">
        <div className="w-48 h-14 flex items-center justify-center">
          {imageAccessLink ? (
            <img
              src={imageAccessLink}
              alt="Company Logo"
              className="w-full h-full object-contain object-left"
            />
          ) : (
            <div className="bg-neutral-100 w-full h-full flex items-center justify-center">
              {t("Company logo")}
            </div>
          )}
        </div>
        <div className="bg-green-400 px-3 py-2">
          <Label14>1. General Information</Label14>
        </div>
        <div className="grid grid-cols-2 gap-y-2 gap-x-6">
          <div className="bg-neutral-100 h-4 w-3/4 rounded" />
          <div className="bg-neutral-100 h-4 w-1/2 rounded" />
          <div className="bg-neutral-100 h-4 w-1/2 rounded" />
          <div className="bg-neutral-100 h-4 w-1/2 rounded" />
          <div className="bg-neutral-100 h-4 w-3/4 rounded" />
          <div className="bg-neutral-100 h-4 w-3/4 rounded" />
        </div>
      </div>
    </div>
  );
};
