import { ajvResolver } from "@hookform/resolvers/ajv";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchPlant } from "../../api/endpoints/plants";
import { DynamicFormJSONSchema, Plant } from "../../api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { JSONSchemaFieldConnected } from "../../components/JSONSchemaFieldConnected";
import { ProductionForm } from "../../pages/Production";
import { useManufacturers } from "../../state/manufacturers";
import { usePlants } from "../../state/plants";
import { showSuccessToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";

export const WasteTreatment = ({
  refetchPlants,
  ...props
}: {
  refetchPlants: () => void;
  editing?: ProductionForm;
  setEditing: (value?: ProductionForm) => void;
}) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useManufacturers();
  const { activePlant, setActivePlantId } = usePlants();

  const [loading, setLoading] = useState(false);
  const patchPlant = usePatchPlant();

  const onSubmit: SubmitHandler<Record<string, string>> = async (fields) => {
    if (!activeManufacturer || !activePlant) return;
    try {
      setLoading(true);
      const plant = await patchPlant({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        plant: {
          ...activePlant,
          waste_treatment: fields,
        },
      });
      setActivePlantId(plant.id);
      refetchPlants();
      showSuccessToast(t("Successfully updated waste treatment settings"));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    activePlant && (
      <WasteTreatmentForm
        activePlant={activePlant}
        loading={loading}
        onSubmit={onSubmit}
        {...props}
      />
    )
  );
};

export const WasteTreatmentForm = ({
  activePlant,
  loading,
  onSubmit,
  editing,
  setEditing,
}: {
  activePlant: Plant;
  loading: boolean;
  onSubmit: SubmitHandler<Record<string, string>>;
  editing?: ProductionForm;
  setEditing: (value?: ProductionForm) => void;
}) => {
  const isEditMode = editing === ProductionForm.WasteTreatment;
  const isEditingOtherForm = editing && !isEditMode;

  const { t } = useTranslation();

  const methods = useForm<Record<string, string>>({
    // @ts-expect-error TODO: fix AJV types expecting a more generic JSON Schema type
    resolver: ajvResolver(activePlant.waste_treatment_schema, { strict: false }),
    defaultValues: activePlant.waste_treatment ?? {},
  });

  useWarnBeforeUnload(isEditMode && methods.formState.isDirty);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) => {
          onSubmit(values);
          setEditing(undefined);
        })}
        noValidate
      >
        <Card
          title={t("Waste Treatment")}
          input={
            isEditMode ? (
              <>
                <Button
                  intent="transparent"
                  size="small"
                  type="button"
                  onPress={() => {
                    methods.reset(activePlant.waste_treatment);
                    setEditing(undefined);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button intent="primary" size="small" type="submit">
                  {t("Save data")}
                  {loading ? <CircularProgress size="24px" /> : <LockOpenOutlined />}
                </Button>
              </>
            ) : (
              <Button
                intent="transparent"
                size="small"
                type="button"
                isDisabled={isEditingOtherForm}
                onPress={() => setEditing(ProductionForm.WasteTreatment)}
              >
                {t("Edit data")}
                <LockOutlined />
              </Button>
            )
          }
        >
          <div className="space-y-4">
            <WasteTreatmentFields
              schema={activePlant.waste_treatment_schema}
              isEditMode={isEditMode}
            />
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};

const WasteTreatmentFields = ({
  schema,
  isEditMode,
}: {
  schema: DynamicFormJSONSchema;
  isEditMode?: boolean;
}) => {
  return (
    <div className="flex-grow pt-3">
      <div className="grid xl:grid-cols-4 grid-cols-3 gap-8">
        {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
          <JSONSchemaFieldConnected
            key={fieldname}
            schema={schema}
            isDisabled={!isEditMode}
            fieldName={fieldname}
            fieldConfig={fieldConfig}
          />
        ))}
      </div>
    </div>
  );
};
