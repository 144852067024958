import tw from "tailwind-styled-components";

export const ClickableCard = tw("div")`
  rounded-3xl
  cursor-pointer
  bg-white
  p-5

  border border-neutral-300
  hover:border-energyyellow
  hover:-translate-y-[1px]
  active:translate-y-0
  hover:drop-shadow
`;
