import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useGetProduct, useGetProducts } from "../api/endpoints/products";
import { Product } from "../api/types";
import { useActiveManufacturer, useManufacturers } from "./manufacturers";
import { useActivePlant } from "./plants";

/*
  A "product"
  - can be viewed viewed on the overview
  - can be "opened" and edited
  - lives with other products on the manufacturer's overview.
*/

const initialState = {
  initialized: false,
  setInitialized: () => {},
  products: [],
  setProducts: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [initialized, setInitialized] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  return { products, setProducts, initialized, setInitialized };
};

export const ProductsProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useProducts = () => {
  return useContext(Context);
};

export const useProductById = (id?: string) => {
  const { products } = useProducts();

  return useMemo(
    () => (!id ? undefined : products.find((product) => product.id === id)),
    [products, id],
  );
};

export const useSelectedProduct = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("selectedProduct") || undefined;

  return useProductById(id);
};

export const useProductsLoader = () => {
  const { activeManufacturer } = useManufacturers();
  const { activePlant } = useActivePlant();

  const { setProducts, setInitialized } = useProducts();
  const getProducts = useGetProducts();

  const loading = useRef(false);

  const loadProducts = useCallback(async () => {
    if (!activeManufacturer || !activePlant) return;
    loading.current = true;
    const result = await getProducts({
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
    });
    setProducts(result);
    setInitialized(true);
    loading.current = false;
  }, [activeManufacturer, activePlant, getProducts, setProducts, setInitialized]);

  useEffect(() => {
    if (!loading.current) {
      loadProducts();
    }
  }, [loadProducts, activePlant]);

  return { refetch: loadProducts };
};

const useLoadProduct = (id?: string) => {
  const { setProducts } = useProducts();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const getProduct = useGetProduct();

  return useCallback(async () => {
    if (!id || !activeManufacturer || !activePlant) return;

    const result = await getProduct({
      id,
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
    });
    setProducts((products) => {
      const index = products.findIndex((product) => product.id === id);
      return index === -1
        ? [...products, result]
        : products.map((product, i) => (i === index ? result : product));
    });
  }, [id, activeManufacturer, getProduct, setProducts, activePlant]);
};

export const useProductLoader = (id?: string) => {
  const loadProduct = useLoadProduct(id);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  return { refetch: loadProduct };
};

export const useLoadSelectedProduct = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("selectedProduct") || undefined;

  return useLoadProduct(id);
};

export const useSelectedProductLoader = () => {
  const loadProduct = useLoadSelectedProduct();

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);
};

export const useUpsertProduct = () => {
  const { setProducts } = useProducts();

  return useCallback(
    (product: Product) => {
      setProducts((products) => {
        const index = products.findIndex((p) => p.id === product.id);

        return index === -1
          ? [...products, product]
          : products.map((p, i) => (i === index ? product : p));
      });
    },
    [setProducts],
  );
};
