import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Impact } from "../../api/types";
import { Label, Text14 } from "../../components/Typography";
import { formatPrecision } from "../../util/format";

const useImpactFactorLabels = (): Record<string, string> => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      "GWP-total": t("Global Warming Potential"),
      ODP: t("Depletion of the stratospheric ozone layer"),
      AP: t("Acidification"),
      "EP-freshwater": t("Eutrophication"),
      POCP: t("Photochemical Ozone Creation"),
      ADPE: t("Abiotic depletion (non-fossil)"),
      ADPF: t("Abiotic depletion (fossil resources)"),
      WDP: t("Water deprivation"),
      PM: t("Particulate matter"),
      PERT: t("Photochemical ozone creation potential"),
      FW: t("Freshwater ecotoxicity"),
    };
  }, [t]);
};

export const ImpactFactors = ({ impacts }: { impacts: Impact[] }) => {
  const labels = useImpactFactorLabels();
  return (
    <div className="grid grid-cols-2 gap-6">
      {impacts.map((impact) => (
        <div key={impact.indicator_name} className="flex flex-col gap-1">
          <Text14>
            {labels[impact.indicator_name]} ({impact.indicator_name})
          </Text14>
          <Label className="flex flex-col gap-2">
            {formatPrecision(impact.value, 4)} {impact.unit}
          </Label>
        </div>
      ))}
    </div>
  );
};
