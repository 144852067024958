import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetPlantInputs } from "../api/endpoints/inputs";
import { useGetSupplierPlants } from "../api/endpoints/suppliers";
import { PlantInput } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

const initialState = {
  initialized: false,
  setInitialized: () => {},
  materials: [],
  setMaterials: () => {},
  rawMaterials: [],
  setRawMaterials: () => {},
  packagingMaterials: [],
  setPackagingMaterials: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [rawMaterials, setRawMaterials] = useState<PlantInput[]>([]);
  const [packagingMaterials, setPackagingMaterials] = useState<PlantInput[]>([]);
  const [initialized, setInitialized] = useState(false);

  return {
    rawMaterials,
    setRawMaterials,
    packagingMaterials,
    setPackagingMaterials,
    initialized,
    setInitialized,
  };
};

export const InputsProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useInputs = () => {
  return useContext(Context);
};

export const useInputsLoader = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const { setRawMaterials, setPackagingMaterials, setInitialized } = useInputs();

  const getInputs = useGetPlantInputs();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  const load = useCallback(async () => {
    if (!activeManufacturer || !activePlant) return;
    loading.current = true;
    try {
      const [resultRawMaterials, resultPackagingMaterials] = await Promise.all([
        getInputs({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          type: "raw_materials",
        }),
        getInputs({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          type: "packaging",
        }),
      ]);
      setRawMaterials(resultRawMaterials);
      setPackagingMaterials(resultPackagingMaterials);
    } catch (e) {
      console.error(e);
    }
    loading.current = false;
    setInitialized(true);
  }, [
    activeManufacturer,
    activePlant,
    getInputs,
    setInitialized,
    setPackagingMaterials,
    setRawMaterials,
  ]);

  useEffect(() => {
    if (!loading.current) {
      load();
    }
  }, [load]);

  return {
    refetch: load,
  };
};

export const useLoadSupplierPlants = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();

  const getSupplierPlants = useGetSupplierPlants();

  return useCallback(
    (supplierId: string) => {
      if (!activeManufacturer || !activePlant) return Promise.resolve([]);

      return getSupplierPlants({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        supplierId,
      });
    },
    [activeManufacturer, activePlant, getSupplierPlants],
  );
};

export const usePrechainProducts = () => {
  const { rawMaterials } = useInputs();

  return rawMaterials.filter((x) => x.prechain_product).map((x) => x.prechain_product!);
};
