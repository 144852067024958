import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetMe } from "../api/endpoints/manufacturers";
import { useGetProductCategories } from "../api/endpoints/product-categories";
import { Manufacturer, ProductCategory } from "../api/types";

const initialState = {
  initialized: false,
  setInitialized: () => {},
  manufacturers: [],
  setManufacturers: () => {},
  activeManufacturer: undefined,
  setActiveManufacturer: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [activeManufacturer, setActiveManufacturer] = useState<Manufacturer | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  return {
    manufacturers,
    setManufacturers,
    activeManufacturer,
    setActiveManufacturer,
    initialized,
    setInitialized,
  };
};

export const ManufacturersProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useManufacturers = () => {
  return useContext(Context);
};

export const useActiveManufacturer = () => {
  const { activeManufacturer, initialized } = useManufacturers();

  return { activeManufacturer, initialized };
};

export const useManufacturersLoader = () => {
  const { manufacturers, setManufacturers, setActiveManufacturer, setInitialized } =
    useManufacturers();

  const getManufacturers = useGetMe();

  // To avoid fetching twice, also during dev mode,
  // when react calls hooks twice to check for changes
  const loading = useRef(false);

  const load = useCallback(async () => {
    loading.current = true;
    try {
      const result = await getManufacturers();
      // TODO: will soon actually return a manufacturers array
      setManufacturers([result]);
      setActiveManufacturer(result);
    } catch (e) {
      console.error(e);
    }
    loading.current = false;
    setInitialized(true);
  }, [getManufacturers, setManufacturers, setActiveManufacturer, setInitialized]);

  useEffect(() => {
    if (!loading.current && (!manufacturers || manufacturers.length === 0)) {
      load();
    }
  }, [load, manufacturers]);

  return { refetch: load };
};

export const useProductCategories = () => {
  const getProductCategories = useGetProductCategories();
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    const load = async () => {
      try {
        const categories = await getProductCategories();
        setProductCategories(categories);
      } catch (e) {
        console.error(e);
      }
    };
    load();
  }, [getProductCategories]);

  return productCategories;
};

export const useActiveProductCategories = () => {
  const productCategories = useProductCategories();
  const productCategoriesMap = useMemo(() => {
    return Object.fromEntries(productCategories.map((category) => [category.id, category]));
  }, [productCategories]);

  return {
    productCategories,
    productCategoriesMap,
  };
};
