import { ComponentProps } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumberField } from "../components/NumberField";

export const NumberFieldConnected = <TFieldValues extends object>({
  name,
  isRequired,
  rules,
  ...props
}: Pick<ControllerProps<TFieldValues>, "name" | "rules"> & ComponentProps<typeof NumberField>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? t("Required") : undefined,
        min: props.minValue,
        max: props.maxValue,
        validate: (value) => {
          // If the field is required and the value is not a number, return an error
          if (isRequired && isNaN(value)) {
            return t("Required");
          }

          return true;
        },
        ...rules,
      }}
      render={({ field: { value, disabled, ...field }, fieldState: { error } }) => (
        <NumberField
          {...field}
          {...props}
          value={value}
          errorMessage={error?.message}
          isRequired={isRequired}
        />
      )}
    />
  );
};
