import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePatchProduct, usePostProduct } from "../../api/endpoints/products";
import { Product } from "../../api/types";
import { ProductDetailsFields } from "../../page-components/product-details/ProductDetailsFields";
import { ProductDetailsForm } from "../../page-components/product-details/types";
import { useElementaries } from "../../state/elementaries";
import { useActiveProductCategories, useManufacturers } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import {
  useLoadSelectedProduct,
  useProductsLoader,
  useSelectedProduct,
  useUpsertProduct,
} from "../../state/products";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";

const getValuesFromProduct = (product: Product): Partial<ProductDetailsForm> => {
  return {
    elementary_id: product.elementary_id,
    name: product.name,
    description: product.description,
    year_under_review: product.year_under_review,
    production_output: product.production_output,
    referenceUsePeriod: product.category.reference_service_life_text?.en ?? "",
    use_case: product.use_case,
    unit: product.unit,
  };
};

export const ProductDetails = () => {
  useProductsLoader();

  const { t } = useTranslation();
  const selectedProduct = useSelectedProduct();

  const defaultValues = selectedProduct ? getValuesFromProduct(selectedProduct) : undefined;
  const methods = useForm<ProductDetailsForm>({
    defaultValues,
  });

  useWarnBeforeUnload(methods.formState.isDirty);

  const { elementariesMap } = useElementaries();
  const { productCategoriesMap } = useActiveProductCategories();

  useEffect(
    function populateForm() {
      if (!selectedProduct) return;

      methods.reset(getValuesFromProduct(selectedProduct));
    },
    [selectedProduct, productCategoriesMap, methods],
  );

  const postProduct = usePostProduct();
  const patchProduct = usePatchProduct();
  const { activeManufacturer } = useManufacturers();
  const { activePlant } = useActivePlant();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const loadSelectedProduct = useLoadSelectedProduct();
  const upsertProduct = useUpsertProduct();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ProductDetailsForm> = async ({ categoryName, ...values }) => {
    if (!activeManufacturer || !activePlant) return;

    try {
      setLoading(true);
      if (selectedProduct) {
        await patchProduct({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          productId: selectedProduct.id,
          product: {
            ...selectedProduct,
            ...values,
          },
        });
        await loadSelectedProduct();
      } else {
        const response = await postProduct({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          product: {
            ...values,
            tech_specs: null,
          },
        });
        upsertProduct(response);
        searchParams.set("selectedProduct", response.id);
      }
      setLoading(false);

      // Append search param for the now existing product
      // to ensure the product gets loaded
      // when users go BACK from /edit/product-specs to /edit/product-details
      navigate({ search: searchParams.toString() });

      navigate({ pathname: "/edit/product-specs", search: searchParams.toString() });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const elementaryId = methods.watch("elementary_id");

  useEffect(() => {
    if (!elementaryId) return;

    const elementary = elementariesMap[elementaryId];
    const category = productCategoriesMap[elementary.product_category_id];

    methods.setValue("description", category?.product_description.en ?? "");
    methods.setValue("referenceUsePeriod", category?.reference_service_life_text?.en ?? "");
    methods.setValue("use_case", category?.use_case.en ?? "");
  }, [
    activeManufacturer,
    activePlant,
    elementariesMap,
    productCategoriesMap,
    methods,
    elementaryId,
  ]);

  return (
    <div className="flex flex-col flex-grow overflow-hidden">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex-grow flex flex-col justify-between w-full gap-5 pt-5 pb-20 overflow-hidden"
          noValidate
        >
          <ProductDetailsFields existingProduct={!!selectedProduct} />
          <div className="flex flex-col justify-end">
            <EditFlowNav
              nextSubmit
              onPrev={() => navigate("/products")}
              prevLabel={t("Cancel")}
              nextLoading={loading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
