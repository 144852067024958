import { useEffect, useState } from "react";
import { useGetMaterial } from "../api/endpoints/materials";
import { SupplierMaterial } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

export const useMaterial = (id?: string) => {
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant } = useActivePlant();
  const getMaterial = useGetMaterial();

  const [data, setData] = useState<SupplierMaterial | null>(null);

  useEffect(() => {
    if (!activeManufacturer || !activePlant || !id) return;

    getMaterial({
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
      materialId: id,
    }).then(setData);
  }, [activeManufacturer, activePlant, getMaterial, id]);

  return data;
};
