import { useGetEditableEPD, useGetReasons } from "../../api/endpoints/declarations";
import { Product } from "../../api/types";

export const useGetEditableEPDByOperator = ({ product }: { product?: Product }) => {
  const getEditableEPD = useGetEditableEPD();
  const getReasons = useGetReasons();

  return async (operatorId: string) => {
    if (!product || !operatorId) {
      return;
    }

    try {
      const token = prompt("Enter emidat token");
      if (!token) {
        return;
      }

      const reasons = await getReasons({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      });

      const epdHtml = await getEditableEPD({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
        reasonId: reasons[0].id,
        programOperatorId: operatorId,
        token,
      });

      const href = URL.createObjectURL(new Blob([epdHtml], { type: "text/html" }));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "epd.html");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error(e);
    }
  };
};
