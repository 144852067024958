import { CheckOutlined } from "@mui/icons-material";
import { animated, config, useSpring, useTransition } from "@react-spring/web";
import { useEffect, useState } from "react";
import { Splash, useSplash } from "../state/splash";
import { Heading3 } from "./Typography";

const boxes = [
  { id: 1, x: "10%", y: "20%", size: "h-20 w-20", inFrom: "-400px", delay: 0 },
  { id: 2, x: "20%", y: "10%", size: "h-20 w-20", inFrom: "-500px", delay: 100 },
  { id: 3, x: "85%", y: "50%", size: "h-20 w-20", inFrom: "400px", delay: 80 },
  { id: 4, x: "60%", y: "80%", size: "h-20 w-20", inFrom: "500px", delay: 120 },
  { id: 5, x: "74%", y: "70%", size: "h-20 w-20", inFrom: "600px", delay: 180 },
];

export const SplashSuccess = () => {
  const { splash, setSplash } = useSplash();

  useEffect(() => {
    if (!splash) return;

    const timeout = setTimeout(() => {
      setSplash(undefined);
    }, 1600);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [splash, setSplash]);

  const fadeOutOnUnmountTransitions = useTransition(splash, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  return fadeOutOnUnmountTransitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="fixed flex items-center justify-center bg-neutral-300 bg-texture bg-repeat top-0 left-0 w-screen h-screen z-50"
        >
          <SplashSuccessContent splash={splash} />
        </animated.div>
      ),
  );
};

const SplashSuccessContent = ({ splash }: { splash?: Splash }) => {
  const [message] = useState(splash?.message);

  const shrink = useSpring({
    from: { opacity: 0, transform: "scale(0.6)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: config.wobbly,
  });

  const springs = boxes.map((box) =>
    // wish there was a better way to do this without a hook in a loop
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSpring({
      from: { transform: `translateY(${box.inFrom})` },
      to: { transform: "translateX(0)" },
      delay: box.delay,
      config: { mass: 4, tension: 800, friction: 80 },
      duration: 200,
    }),
  );

  return (
    <>
      <div className="absolute w-full h-full">
        <div className="relative w-full h-full">
          {springs.map((spring, index) => (
            <animated.div
              key={index}
              style={{
                ...spring,
                top: boxes[index].y,
                left: boxes[index].x,
              }}
              className={`bg-energyyellow bg-texture absolute drop-shadow ${boxes[index].size}`}
            />
          ))}
        </div>
      </div>

      <div className="text-2xl font-bold flex flex-col items-center gap-3">
        <animated.div
          className="bg-energyyellow text-builtgreen w-16 h-16 flex items-center justify-center shadow"
          style={shrink}
        >
          <CheckOutlined fontSize="large" />
        </animated.div>
        <Heading3 className="text-builtgreen">{message}</Heading3>
      </div>
    </>
  );
};
