import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const HARD_CODED_CONCRETE_CATEGORY_ID = "6a68b08b-786a-46f1-ac83-8eab240dd96c";
export const HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID = "fc7b3c1d-00fe-40cb-8455-b9c0bd4e326e";
export const HARD_CODED_MINERAL_INSULATING_MATERIALS_ID = "6ea590b1-602a-44d6-ab8d-f2371177a010";
export const HARD_CODED_AGGREGATES_ID = "a08eff44-36d5-4822-96d6-c9c59629f78b";
export const HARD_CODED_CEMENT_ID = "d4b76709-dd15-4de1-b6c7-eba401a62bf6";
export const HARD_CODED_ASPHALT_ID = "269e73d0-868a-4ed7-8386-7f511ac4ae7f";
export const HARD_CODED_REACTION_RESIN_ID = "8dad0739-5e9b-4b10-920b-38075970d52f";
export const HARD_CODED_CLINKER_ID = "78779907-80b4-46b6-a216-88f7b40bf356";
export const HARD_CODED_GGBS_ID = "41b4a112-0f04-4131-8ff3-19491f0277ce";
export const HARD_CODED_GREEN_ROOF_SYSTEMS_ID = "ab763313-9fee-46a1-b784-4189dbdc2a6b";

export const CATEGORIES_WITHOUT_RECIPE = [HARD_CODED_AGGREGATES_ID];

// -------------------
// TODO: move to backend or to some place where we define product categories
export const useHardCodedConcreteMustContainValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t("Cement (or substitutes)"),
        fromCategories: ["cement", "ggbs", "fly ash", "silica fume powder"],
      },
      {
        label: t("Aggregates"),
        fromCategories: [
          "gravel",
          "natural aggregates",
          "sand",
          "limestone",
          "recycled concrete",
          "recycled brick",
          "recycled glass",
          "rock dust",
          "natural stone",
          "sand and gravel",
        ],
      },
      {
        label: t("Water"),
        fromCategories: ["water"],
      },
    ],
    [t],
  );
};
// -------------------
