import tw from "tailwind-styled-components";

export const Badge = tw("div")`
  inline-block
  px-3 py-2
  bg-neutral-200
  opacity-70
  uppercase
  text-sm
  font-bold
  text-neutral-500
`;
