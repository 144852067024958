import { useState } from "react";
import { ProductionProcess } from "../../api/types";
import { useActiveProductCategories } from "../../state/manufacturers";
import { EmptyProductionProcessList } from "./EmptyProductionProcessList";
import { ProductionProcessCard } from "./ProductionProcessCard";

export const ProductionProcessList = ({
  activeYear,
  productionProcesses,
}: {
  activeYear: number;
  productionProcesses: ProductionProcess[];
}) => {
  const { productCategoriesMap } = useActiveProductCategories();

  const [editingProcessId, setEditingProcessId] = useState<string | null>(null);

  return (
    <>
      {productionProcesses.length === 0 && <EmptyProductionProcessList />}
      <div className="space-y-6">
        {productionProcesses
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((process) => (
            <ProductionProcessCard
              isEditing={editingProcessId === process.id}
              isEditingOtherForm={!!editingProcessId && editingProcessId !== process.id}
              setEditingProcessId={setEditingProcessId}
              key={process.id}
              process={process}
              activeYear={activeYear}
              productCategory={productCategoriesMap[process.category_id]}
            />
          ))}
      </div>
    </>
  );
};
