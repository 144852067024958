import { useState } from "react";
import { usePostSearchResults } from "../api/endpoints/search";
import type { EPDSearchResult } from "../api/types";
import { SearchForm } from "../page-components/SearchForm";
import { SearchResults } from "../page-components/SearchResults";

interface SearchFormValues {
  productName: string;
  manufacturer: string;
  language?: string;
}

const useSearch = () => {
  const [loading, setLoading] = useState(false);
  const postSearchResults = usePostSearchResults();

  const onSubmitSearch = async ({ productName, manufacturer, language }: SearchFormValues) => {
    try {
      setLoading(true);
      const results = await postSearchResults({
        searchTerm: [productName, manufacturer].join(" "),
        language,
      });
      setLoading(false);
      return results;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return {
    loading,
    onSubmitSearch,
  };
};

export const Search = () => {
  const [searchResults, setSearchResults] = useState<EPDSearchResult[]>([]);

  const { loading, onSubmitSearch } = useSearch();

  const [searchedLanguage, setSearchedLanguage] = useState<string | undefined>(undefined);

  const onSubmit = async (values: SearchFormValues) => {
    setSearchedLanguage(values.language?.toLowerCase());

    const results = await onSubmitSearch(values);

    if (results) {
      setSearchResults(results);
    }
  };

  return (
    <div className="flex flex-col gap-6 pb-8">
      <SearchForm onSubmit={onSubmit} loading={loading} />
      <SearchResults searchedLanguage={searchedLanguage} results={searchResults} />
    </div>
  );
};
