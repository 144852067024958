import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { useGetPlants } from "../api/endpoints/plants";
import { Plant } from "../api/types";

const initialState = {
  plants: [],
  activePlant: undefined,
  activePlantId: undefined,
  setActivePlantId: () => {},
  initialized: false,
  setPlants: () => {},
  setInitialized: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [plants, setPlants] = useState<Plant[]>([]);
  const [activePlantId, setActivePlantId] = useLocalStorage<string>("activePlantId");
  const [initialized, setInitialized] = useState(false);

  const activePlant = useMemo(
    () => plants.find(({ id }) => id === activePlantId),
    [plants, activePlantId],
  );

  return {
    plants,
    activePlant,
    activePlantId,
    setActivePlantId,
    initialized,
    setPlants,
    setInitialized,
  };
};

export const PlantsProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const usePlants = () => {
  return useContext(Context);
};

export const useActivePlant = () => {
  const { activePlant, initialized } = usePlants();
  return { activePlant, initialized };
};

export const usePlantsLoader = ({ manufacturerId }: { manufacturerId?: string }) => {
  const getPlants = useGetPlants();

  const { setPlants, activePlantId, setInitialized, setActivePlantId } = usePlants();

  const load = useCallback(async () => {
    try {
      if (!manufacturerId) return;

      const response = await getPlants({
        manufacturerId,
      });
      setPlants(response);

      if (!activePlantId && response.length > 0) {
        setActivePlantId(response[0].id);
      }

      setInitialized(true);
    } catch (e) {
      console.error(e);
    }
  }, [activePlantId, getPlants, manufacturerId, setActivePlantId, setInitialized, setPlants]);

  useEffect(() => {
    load();
  }, [load]);

  return { refetch: load };
};
