import { Button as ButtonPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { Impact, SourceImpacts } from "../../api/types";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { Label12, Label14 } from "../../components/Typography";
import { coreIndicators } from "../../lca/impact";
import { useImpactColors } from "./useImpactColors";

const Cell = tw.div`
  border-r border-b border-neutral-300 px-2 py-4 line-clamp-1 h-full flex items-center relative
`;

export type Totals = Record<Impact["indicator_name"], number>;

export const BreakdownPerMaterialChart = ({
  impactsPerSource,
  totals,
}: {
  impactsPerSource: SourceImpacts[];
  totals: Totals;
}) => {
  const { t } = useTranslation();

  const colors = useImpactColors();

  return (
    <div className="grid grid-cols-[300px_repeat(8,1fr)] border-b border-t border-neutral-300 items-center pb-10">
      <Cell>{t("Source")}</Cell>
      {Array.from(coreIndicators).map((indicator) => (
        <Cell key={indicator} className="flex items-center gap-2">
          <div
            className="w-2 h-2 rounded-full flex-shrink-0"
            style={{ backgroundColor: colors[indicator] }}
          />
          <Label12 className="line-clamp-1" title={indicator}>
            {indicator}
          </Label12>
        </Cell>
      ))}
      {impactsPerSource.map((impactPerSource) => {
        const data = Array.from(coreIndicators).map((indicator) => {
          const totalImpact = impactPerSource.impacts_total.find(
            (x) => x.indicator_name === indicator,
          );
          const transportImpact = impactPerSource.impacts_transport.find(
            (x) => x.indicator_name === indicator,
          );

          const totalValue = totalImpact?.value ?? 0;
          const unit = totalImpact?.unit ?? "";
          const transportValue = transportImpact?.value ?? 0;

          return {
            indicator_name: indicator,
            total: totalValue,
            total_share: (totalValue / totals[indicator]) * 100,
            transport_share: (transportValue / totals[indicator]) * 100,
            element_share: ((totalValue - transportValue) / totals[indicator]) * 100,
            unit,
          };
        });

        return (
          <>
            <Cell key={impactPerSource.name + "header"}>
              <Label14 title={impactPerSource.name} className="line-clamp-1">
                {impactPerSource.name}
              </Label14>
            </Cell>
            {data.map((cell, index) => {
              return (
                <Cell
                  className="flex justify-start items-center gap-1"
                  key={impactPerSource.name + index}
                >
                  <TooltipTrigger>
                    <ButtonPrimitive
                      className="h-full flex items-center"
                      style={{
                        width: `${Math.abs(cell.total_share)}%`,
                      }}
                    >
                      <div
                        className="h-full"
                        style={{
                          width: `${(cell.element_share / cell.total_share) * 100}%`,
                          backgroundColor: colors[cell.indicator_name],
                        }}
                      />
                      <div
                        className="h-full opacity-50"
                        style={{
                          width: `${(cell.transport_share / cell.total_share) * 100}%`,
                          backgroundColor: colors[cell.indicator_name],
                        }}
                      />
                    </ButtonPrimitive>
                    <Tooltip>
                      <strong>
                        {cell.indicator_name}: {cell.total_share.toFixed(1)}% (
                        {cell.total.toFixed(2)} {cell.unit})
                      </strong>
                      <ul className="list-disc pl-4">
                        <li>
                          {t("Element share")}: {cell.element_share.toFixed(1)}%
                        </li>
                        <li>
                          {t("Transport share")}: {cell.transport_share.toFixed(1)}%
                        </li>
                      </ul>
                    </Tooltip>
                  </TooltipTrigger>
                  <Label12 className="text-right">{cell.total_share.toFixed(1)}%</Label12>
                </Cell>
              );
            })}
          </>
        );
      })}
    </div>
  );
};
