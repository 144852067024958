import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useGetProductionProcessTemplates } from "../api/endpoints/process-flows";
import { ProductionProcess, ProductionProcessTemplate } from "../api/types";

const initialState = {
  productionProcessTemplates: [],
  setProductionProcessTemplates: () => {},
  activeFlow: null,
  setActiveFlow: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [productionProcessTemplates, setProductionProcessTemplates] = useState<
    ProductionProcessTemplate[]
  >([]);
  const [activeFlow, setActiveFlow] = useState<ProductionProcess | null>(null);

  return {
    activeFlow,
    setActiveFlow,
    productionProcessTemplates,
    setProductionProcessTemplates,
  };
};

export const ProcessFlowsProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useProductionProcesses = () => {
  return useContext(Context);
};

export const useProductionProcessTemplatesLoader = () => {
  const { setProductionProcessTemplates } = useProductionProcesses();
  const getProductionProcessTemplates = useGetProductionProcessTemplates();

  useEffect(() => {
    async function load() {
      try {
        const response = await getProductionProcessTemplates();
        setProductionProcessTemplates(response);
      } catch (e) {
        console.error(e);
      }
    }
    load();
  }, [getProductionProcessTemplates, setProductionProcessTemplates]);
};
