import { memo } from "react";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import { NavbarItemType } from "../config/useNavbarItems";
import { useGetLinkWithParams } from "../url/useGetLinkWithParams";
import { IconContainer } from "./IconContainer";

export const ItemContainer = tw("div")<{
  $active?: boolean;
  $child?: boolean;
  $collapsed?: boolean;
  $disabled?: boolean;
}>`
  flex
  items-center
  gap-5
  ${({ $collapsed }) => (!$collapsed ? "pl-[75px]" : "pl-4")}
  py-3
  px-4
  font-bold

  ${({ $collapsed }) => $collapsed && "justify-end"}
  text-builtgreen
  cursor-pointer

  hover:bg-neutral-200
  active:bg-neutral-200
  group

  ${({ $disabled }) => $disabled && "cursor-not-allowed"}
`;

const isSomeChildActive = (item: NavbarItemType, activePath: string) => {
  return item.children?.some((child) => activePath.startsWith(child.path));
};

const isActiveChild = (item: NavbarItemType, activePath: string) => {
  return activePath.startsWith(item.path + "/");
};

const isHomescreenWithOpenProductDetail = (item: NavbarItemType, activePath: string) => {
  return item.path === "/" && activePath.startsWith("/products/");
};

export const NavbarItem = memo(
  ({
    item,
    activePath,
    collapsed,
    pale,
  }: {
    item: NavbarItemType;
    activePath: string;
    collapsed?: boolean;
    pale?: boolean;
  }) => {
    const getLinkWithParams = useGetLinkWithParams();
    const active =
      activePath === item.path ||
      isActiveChild(item, activePath) ||
      isSomeChildActive(item, activePath) ||
      isHomescreenWithOpenProductDetail(item, activePath);

    return (
      <Link to={getLinkWithParams(item.path)}>
        <ItemContainer $active={active}>
          <IconContainer $active={active} $pale={pale} $bordered={pale}>
            {item.icon && <item.icon />}
          </IconContainer>
          {!collapsed && <div>{item.name}</div>}
        </ItemContainer>
      </Link>
    );
  },
);
