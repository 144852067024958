import { AddOutlined } from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInviteUser } from "../api/endpoints/profiles";
import { Button } from "../components/Button";
import { DataGrid } from "../components/DataGrid";
import { Heading3, Label } from "../components/Typography";
import { TextFieldConnected } from "../form-components/TextFieldConnected";
import { useActiveManufacturer } from "../state/manufacturers";
import { useMyProfileLoader, useProfiles, useProfilesLoader } from "../state/profiles";
import { showErrorToast, showSuccessToast } from "../util/toasts";

export const ManageUsers = () => {
  const { t } = useTranslation();

  useMyProfileLoader();
  const { refetch } = useProfilesLoader();
  const { profiles, myProfile } = useProfiles();

  const [isInvitationModelOpen, setIsInvitationModelOpen] = useState(false);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "email",
        headerName: t("User"),
        width: 400,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-3">
            <PersonOutlineIcon />
            {value}
          </div>
        ),
      },
      {
        field: "is_owner",
        headerName: t("Role"),
        width: 200,
        valueGetter: (value) => (value ? t("Owner") : t("User")),
      },
    ];
  }, [t]);

  return (
    <>
      <div className="flex-grow flex flex-col gap-8 py-8">
        <div className="flex items-end justify-between gap-3">
          <div>
            <Label>{t("User management")}</Label>
          </div>
          {myProfile?.is_owner && (
            <div className="flex gap-6">
              <Button intent="secondary" onPress={() => setIsInvitationModelOpen(true)}>
                <AddOutlined />
                {t("Invite User")}
              </Button>
            </div>
          )}
        </div>
        <DataGrid
          className="h-full bg-white"
          rows={profiles}
          columns={columns}
          rowHeight={35}
          hideFooterPagination
          disableRowSelectionOnClick
        />
      </div>
      {isInvitationModelOpen && (
        <InviteUserModal
          onConfirm={() => {
            setIsInvitationModelOpen(false);
            refetch();
          }}
          onCancel={() => setIsInvitationModelOpen(false)}
        />
      )}
    </>
  );
};

interface InviteUserForm {
  email: string;
}

const InviteUserModal = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();

  const sendInivation = useInviteUser();

  const methods = useForm<InviteUserForm>({
    defaultValues: { email: "" },
  });

  const onSubmit: SubmitHandler<InviteUserForm> = async ({ email }) => {
    if (!activeManufacturer) return;
    try {
      await sendInivation({
        manufacturerId: activeManufacturer.id,
        email,
      });
      showSuccessToast(t("Inivitation send."));
      onConfirm();
    } catch (_) {
      showErrorToast(t("Could not send invitation."));
    }
  };

  return (
    <Dialog open onClose={() => onCancel()}>
      <div className="px-6 py-4">
        <Heading3>{t("Invite User")}</Heading3>
      </div>
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <div className="flex flex-col justify-between min-h-40 pb-4">
              <TextFieldConnected name="email" isRequired label={t("Email Address")} />
              <div>
                {t(
                  "By inviting, the new user receives access to all data of this manufacturer account.",
                )}
              </div>
            </div>
            <DialogActions>
              <Button intent="secondary" onPress={onCancel}>
                {t("Cancel")}
              </Button>
              <Button intent="primary" type="submit">
                {t("Invite")}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};
